import { useEffect, useState } from "react";
import { AtlasMapResponse } from "@hokuto/jam-core";
import { jsonFetch } from "@hokuto/jam-ui";
import {
  MetricSchema,
  GroupResults,
  LocationProps,
  Locations,
  isMetricAvailable,
} from "abpi-common";
import { MetricValue } from "../components/map/Map.types";
import { PaginationRequest } from "../components/table/Table.types";

export const initialPagination: PaginationRequest = {
  pageSize: 50,
  currentPage: 1,
};

export interface AverageSchema {
  count: number;
  value: number;
}

export function useMetric(metric: MetricSchema) {
  const [pagination, setPagination] = useState<PaginationRequest>();
  const [data, setData] = useState<MetricValue[]>();
  const [dataPage, setDataPage] = useState<MetricValue[]>();
  const [countries, setCountries] = useState<GroupResults[]>();
  const [regions, setRegions] = useState<GroupResults[]>();
  const [wpcs, setWpcs] = useState<GroupResults[]>();
  const [levelType, setLevelType] = useState<LocationProps>();
  const [atlasFeatures, setAtlasFeatures] = useState<AtlasMapResponse[]>();
  const [avg, setAvg] = useState<{
    countries?: AverageSchema;
    regions?: AverageSchema;
    wpcs?: AverageSchema;
  }>();

  const { formatter, populationField, totalField } = metric;

  useEffect(() => {
    if (!metric) return;

    const { groupType } = metric;
    if (isMetricAvailable(Locations.Countries, groupType))
      jsonFetch<GroupResults[]>(
        `${process.env.REACT_APP_ABPI_SERVER}/${metric.urlPrefix}/${Locations.Countries.urlSuffix}`
      ).then(setCountries);
    if (isMetricAvailable(Locations.Regions, groupType))
      jsonFetch<GroupResults[]>(
        `${process.env.REACT_APP_ABPI_SERVER}/${metric.urlPrefix}/${Locations.Regions.urlSuffix}`
      ).then(setRegions);
    if (isMetricAvailable(Locations.Wpcs, groupType))
      jsonFetch<GroupResults[]>(
        `${process.env.REACT_APP_ABPI_SERVER}/${metric.urlPrefix}/${Locations.Wpcs.urlSuffix}`
      ).then(setWpcs);

    return () => {
      setCountries(undefined);
      setRegions(undefined);
      setWpcs(undefined);
    };
  }, [metric]);

  useEffect(() => {
    const { groupType } = metric;

    if (isMetricAvailable(Locations.Countries, groupType) && !countries) return;
    if (isMetricAvailable(Locations.Regions, groupType) && !regions) return;
    if (isMetricAvailable(Locations.Wpcs, groupType) && !wpcs) return;

    const countriesValues = countries?.filter((item) => !!item.value);
    const regionsValues = regions?.filter((item) => !!item.value);
    const wpcsValues = wpcs?.filter((item) => !!item.value);
    // console.log(wpcs);
    setAvg({
      countries: countriesValues && {
        value:
          countriesValues.reduce((aggr, item) => aggr + item.value, 0) /
          countriesValues.length,
        count: countriesValues.length,
      },
      regions: regionsValues && {
        value:
          regionsValues.reduce((aggr, item) => aggr + item.value, 0) /
          regionsValues.length,
        count: regionsValues.length,
      },
      wpcs: wpcsValues && {
        value:
          wpcsValues.reduce((aggr, item) => aggr + item.value, 0) /
          wpcsValues.length,
        count: wpcsValues.length,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, regions, wpcs]);

  useEffect(() => {
    if (!atlasFeatures) return;
    const data: MetricValue[] = [];
    for (const map of atlasFeatures) {
      if (map.id === metric.sites?.mapId) continue;
      // const labelProp = map.labelProp;
      // if (!labelProp) continue;
      if (!map.features) continue;

      const isCountries = map.id === Locations.Countries.mapId;
      const isRegions = map.id === Locations.Regions.mapId;
      const isWpcs = map.id === Locations.Wpcs.mapId;

      const idProp = isCountries
        ? Locations.Countries.idProp
        : isRegions
        ? Locations.Regions.idProp
        : isWpcs
        ? Locations.Wpcs.idProp
        : undefined;

      const labelProp = isCountries
        ? Locations.Countries.labelProp
        : isRegions
        ? Locations.Regions.labelProp
        : isWpcs
        ? Locations.Wpcs.labelProp
        : undefined;

      if (!idProp || !labelProp) continue;

      if (isCountries && levelType !== Locations.Countries)
        setLevelType(Locations.Countries);
      else if (isRegions && levelType !== Locations.Regions)
        setLevelType(Locations.Regions);
      else if (isWpcs && levelType !== Locations.Wpcs)
        setLevelType(Locations.Wpcs);

      const values = isCountries
        ? countries
        : isRegions
        ? regions
        : isWpcs
        ? wpcs
        : undefined;

      if (!values) continue;

      for (const feature of map.features) {
        const id = String(feature.properties![idProp]);
        const name = String(feature.properties![labelProp]);
        if (!id) continue;
        if (!name) continue;

        const group = values.find((item) => item.id === id);
        if (!group) continue;
        data.push({
          name,
          levelId: group.id,
          value: group.value,
          ranking: group.ranking,
          featureId: feature.id!,
          mapId: map.id!,
          area: feature.area!,
          centroid: feature.centroid!,
        });
      }

      break;
    }
    setPagination(initialPagination);
    setData(
      data.sort((a, b) =>
        a.ranking === b.ranking
          ? 0
          : !a.ranking
          ? 1
          : !b.ranking
          ? -1
          : a.ranking > b.ranking
          ? 1
          : -1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atlasFeatures, countries, regions, wpcs]);

  useEffect(() => {
    if (!pagination) return;
    if (!data) return;
    const { currentPage, pageSize } = pagination;
    const dataOffset = (currentPage - 1) * pageSize;
    const dataPage = data.slice(dataOffset, dataOffset + pageSize);
    setDataPage(dataPage);
  }, [pagination, data]);

  let currentAvg: AverageSchema | undefined = undefined;

  if (levelType) {
    switch (levelType) {
      case Locations.Countries:
        currentAvg = avg?.countries;
        break;
      case Locations.Regions:
        currentAvg = avg?.regions;
        break;
      case Locations.Wpcs:
        currentAvg = avg?.wpcs;
        break;
    }
  }

  const useTotal = !populationField && !totalField;
  const total =
    useTotal &&
    countries &&
    countries.reduce((total, item) => total + item.value, 0);

  return {
    countries,
    regions,
    wpcs,
    count: currentAvg?.count,
    avg:
      currentAvg &&
      (formatter
        ? formatter.format(currentAvg.value)
        : currentAvg.value.toFixed(2)),
    total: total && (metric.formatter ? metric.formatter.format(total) : total),
    data,
    dataPage,
    pagination,
    levelType,
    setPagination,
    setAtlasFeatures,
  };
}
