export type ElementPosition = {
  top: number;
  left: number;
};
export function elementScroll(
  element?: HTMLElement | null,
  position: ElementPosition = { top: 0, left: 0 }
): ElementPosition {
  if (!element) return position;
  const { top, left } = position;
  return elementScroll(element?.parentElement, {
    top: top + element.scrollTop,
    left: left + element.scrollLeft,
  });
}
