import React from "react";
import { StyledTableRow } from "./styled";
import { DataType, TableRowProps } from "./Table.types";

export function TableRow<T extends DataType>(args: TableRowProps<T>) {
  const { rowType, values, children: RowRenderer, ...props } = args;

  if (!(RowRenderer instanceof Function))
    return (
      <StyledTableRow as={"tr"} {...props}>
        {RowRenderer}
      </StyledTableRow>
    );

  return <RowRenderer rowType={rowType} values={values} />;
}
