import { LogoSchema } from "./Metrics.types";
import { useLogo } from "../../hooks/logo";

export function HealthLogo(args: LogoSchema) {
  const { props, mainColor, foreColor } = useLogo(args);

  return (
    <svg
      viewBox="0 0 170 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M68.4302 49.8675C69.0702 50.6675 69.7501 51.4375 70.4901 52.1775L73.6401 55.3675L115.49 97.8375L157.34 55.3675L160.48 52.1775C171.92 40.5775 171.92 21.7575 160.48 10.1475L160.05 9.7075C148.61 -1.9025 130.07 -1.9025 118.63 9.7075L115.49 12.8975L112.35 9.7075C100.91 -1.9025 82.3702 -1.9025 70.9302 9.7075L70.5001 10.1475C64.7801 15.9475 61.9302 23.5575 61.9302 31.1575"
        stroke={mainColor}
        strokeWidth="1.5"
      />
      <path
        d="M135.7 38.9875H115.9H104.13L97.86 50.7875L89.6901 23.7175L82.48 40.3775H51"
        stroke={mainColor}
        strokeWidth="1.5"
      />
      <path
        d="M1.54503e-06 64L42 64L42 107L-3.34556e-07 107"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M42.2898 98.99L82.5698 122.6C84.0798 123.49 85.8098 123.96 87.5598 123.98L117.55 124.3L117.55 121.2C117.55 116.62 113.13 113.35 108.5 112.6L90.3698 112.6L83.2198 105.87L63.8298 86.59L75.8198 98.5699C78.9898 101.55 84.8498 100.55 87.8498 97.4C87.8498 97.4 90.2498 94.88 90.3198 94.8L71.6698 76.25C69.8598 74.45 67.4698 73.37 64.9298 73.21L53.2798 72.47L42.2798 72.47L42.2798 99L42.2898 98.99Z"
        stroke={foreColor}
        strokeWidth="1.5"
      />
    </svg>
  );
}
