import styled, { css } from "styled-components";
import { rgba } from "polished";
import { SearchProps } from "./Search.types";
import { Icon } from "../icon/main";
import { IconProps } from "../icon/Icon.types";
import { variantTextColor, variantBgColor } from "../../theme/variants";
import { VariantElementProps } from "../../types/components.types";

export const StyledInputContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["disabled", "variant"].includes(prop),
})<SearchProps>`
  flex: 1;
  position: relative;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  transition: color 0.3s;
  ${(props) => {
    return css`
      color: ${rgba(variantBgColor(props), 0.75)};
      background-color: ${variantTextColor(props)};
      &:focus,
      &:focus-within {
        color: ${variantBgColor(props)};
      }
    `;
  }}
`;

export const StyledInput = styled.input.withConfig({
  shouldForwardProp: (prop) => !["variant"].includes(prop),
})<VariantElementProps>`
  font-size: 1rem;
  line-height: 1.5;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  &,
  &::placeholder {
    color: inherit;
  }
`;

export const IconRight = styled(Icon)<IconProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.25rem;
  & ~ input[data-testid="input"] {
    padding-right: 0.5rem;
  }
`;

export const StyledSearchResults = styled.div.withConfig({
  shouldForwardProp: (prop) => !["variant"].includes(prop),
})<VariantElementProps>`
  background-color: ${(props) => variantBgColor(props)};
  color: ${(props) => variantTextColor(props)};
  border: 0.125rem solid ${(props) => variantTextColor(props)};
  box-sizing: border-box;
  max-height: 25vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  h3 {
    padding: 0.25rem;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
  }
  ul {
    max-height: 100%;
    overflow-y: auto;
    margin: 0;
    flex: 1;
    list-style: none;
    padding: 0;
    li {
      cursor: pointer;
      // line-height: 2rem;
      padding: 0.25rem;
      &:hover {
        background-color: ${(props) => rgba(variantTextColor(props), 0.5)};
        color: ${(props) => variantBgColor(props)};
      }
      &:not(:last-child) {
        border-bottom: 0.03rem solid
          ${({ theme }) => variantTextColor({ theme, variant: "off" })};
      }
    }
  }
`;
