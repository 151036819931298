import { useEffect, useReducer, useState } from "react";
import { PinRequest, ProjectionsList } from "@hokuto/jam-core";
import { cancelApiRequest } from "@hokuto/jam-ui";
import { defaultColorScaleSteps } from "abpi-common";
import { PaginationRequest } from "./components/table/Table.types";
import { MapFeatureRel, MetricValue } from "./components/map/Map.types";
import {
  StyledAppContainer,
  StyledBodyContainer,
  StyledColorScaleContainer,
  StyledFeatureTooltipContainer,
  StyledMapContainer,
  StyledSearchContainer,
  StyledSideContainer,
  StyledMetricHeader,
  StyledTableContainer,
  StyledHeaderContainer,
  StyledHeaderOptions,
} from "./styles/containers";
import { Pagination } from "./components/table/pagination";
import { MapComponent } from "./components/map/main";
import { SearchResults } from "./components/search/main";
import { MapTable } from "./components/map/table";
import { getAtlasProps } from "./components/map/options";
import { Card } from "./components/card/main";
import { useSearch } from "./hooks/search";
import { useMetric } from "./hooks/metric";
import { MetricSelection } from "./components/metrics/selection";
import { useMetricLocations } from "./hooks/metric-locations";
import { MapActions } from "./components/map/actions";
import { useSites } from "./hooks/sites";
import { useParamsObserver } from "./hooks/params-observer";

function reduceData(
  state: MetricValue | undefined,
  curr: MetricValue | undefined
): MetricValue | undefined {
  if (state === curr) return undefined;
  return curr;
}

const initialPagination: PaginationRequest = {
  pageSize: 50,
  currentPage: 1,
};

export function App() {
  const {
    currentMetric,
    setCurrentMetric,
    availableLocations,
    currentLocation,
    setCurrentLocation,
  } = useMetricLocations();

  const [mapFeature, selectMapFeature] = useState<MapFeatureRel>();
  const [selectedData, setSelectedData] = useReducer(reduceData, undefined);
  const {
    count,
    avg,
    total,
    data,
    dataPage,
    levelType,
    pagination,
    setPagination,
    setAtlasFeatures,
  } = useMetric(currentMetric);

  const { paramsObserver, error } = useParamsObserver({
    data,
    onChange: setAtlasFeatures,
    onMove: selectMapFeature,
    onClick: setSelectedData,
  });

  const currentSites = useSites({ currentMetric, selectedData, levelType });
  const [pin, setPin] = useState<PinRequest>();
  const { setTextSearch, searchApi, searchResults, setSearchResults } =
    useSearch();

  useEffect(() => {
    setSelectedData(undefined);
  }, [levelType]);

  useEffect(() => {
    pin && paramsObserver.navigatePin(pin);
  }, [pin]);

  function clearResults() {
    setSearchResults(undefined);
    cancelApiRequest(searchApi.dataRequest);
  }

  const metricAtlas = getAtlasProps(currentMetric, currentLocation);

  const hoverLocation =
    mapFeature &&
    data?.find(
      (item) =>
        item.featureId === mapFeature.featureId &&
        item.mapId === mapFeature.mapId
    );

  return (
    <StyledAppContainer>
      <MetricSelection selected={currentMetric} onChange={setCurrentMetric} />
      <StyledHeaderContainer>
        <StyledMetricHeader>
          <div>
            <h3>{currentMetric.label}</h3>
            {currentMetric.description && <p>{currentMetric.description}</p>}
            {currentMetric.links?.map((item) => {
              return (
                <>
                  <a href={item.href} target="_blank">
                    {item.label}
                  </a>
                  <br />
                </>
              );
            })}
          </div>
        </StyledMetricHeader>
        <StyledHeaderOptions>
          <MapActions
            locations={availableLocations}
            selected={currentLocation}
            onSelection={setCurrentLocation}
            onReset={() => {
              paramsObserver.controls?.reset();
            }}
          />
          <StyledSearchContainer
            variant="primary"
            inputProps={{
              placeholder: "Search area (min. 3 letters)",
              autoComplete: "off",
              onChange: (e: React.FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                setTextSearch(target.value);
              },
            }}
            onPopupClose={clearResults}
          >
            {searchApi.showComponent(
              <SearchResults
                variant="primary"
                onSelection={(address) => {
                  setPin({
                    coords: [address.lon, address.lat],
                    radius: 0.1,
                    projection: ProjectionsList.Flat,
                  });
                  setTextSearch("");
                }}
                searchResults={searchResults}
              />
            )}
          </StyledSearchContainer>
        </StyledHeaderOptions>
      </StyledHeaderContainer>
      <StyledBodyContainer>
        <StyledMapContainer>
          <MapComponent
            paramsObserver={paramsObserver}
            paramsError={error}
            currentMetric={currentMetric}
            atlasProps={metricAtlas}
            mapFeature={mapFeature}
            selectedData={selectedData}
          />

          {hoverLocation && count && (
            <StyledFeatureTooltipContainer>
              <strong>
                {hoverLocation.name} (Ranked: {hoverLocation.ranking} of {count}
                )
              </strong>
              <p>
                <b>
                  {currentMetric.label}
                  {currentMetric.labelValueType
                    ? " "
                    : ` ${currentMetric.valueType}`}
                  :
                </b>{" "}
                {currentMetric.formatter
                  ? currentMetric.formatter.format(hoverLocation.value)
                  : hoverLocation.value}
              </p>
            </StyledFeatureTooltipContainer>
          )}
          <StyledColorScaleContainer steps={defaultColorScaleSteps} />
        </StyledMapContainer>
        <StyledSideContainer>
          {selectedData ? (
            <Card
              setPin={setPin}
              selectedData={selectedData}
              currentSites={currentSites}
              levelType={levelType}
              currentMetric={currentMetric}
              onClose={() => setSelectedData(undefined)}
            />
          ) : (
            <>
              <StyledMetricHeader>
                {levelType && (
                  <strong>
                    {total ? `${levelType.label} total: ${total}` : null}
                    {avg && total ? <br /> : null}
                    {avg ? `${levelType.label} average: ${avg}` : null}
                  </strong>
                )}
              </StyledMetricHeader>
              <StyledTableContainer>
                <MapTable
                  levelType={levelType}
                  metric={currentMetric}
                  mapFeature={mapFeature}
                  data={dataPage}
                  selected={selectedData && [selectedData]}
                  onRowClick={setSelectedData}
                  selectMapFeature={selectMapFeature}
                />
              </StyledTableContainer>
              {data && data.length > initialPagination.pageSize && (
                <Pagination
                  variant="secondary"
                  totalCount={data?.length || 0}
                  {...pagination}
                  onChange={(currentPage) =>
                    setPagination({
                      pageSize: initialPagination.pageSize,
                      currentPage,
                    })
                  }
                />
              )}
            </>
          )}
        </StyledSideContainer>
      </StyledBodyContainer>
    </StyledAppContainer>
  );
}
