import React, { forwardRef } from "react";
import { VariantProps } from "../../types/theme.types";
import { ButtonProps } from "./Button.types";
import { StyledButton } from "./styled";

export const Button = forwardRef<HTMLButtonElement, ButtonProps & VariantProps>(
  (args, ref) => {
    let { disabled, variant, ...props } = args;
    variant = disabled ? "disabled" : variant;
    return (
      <StyledButton
        disabled={disabled}
        variant={variant}
        ref={ref}
        {...props}
      />
    );
  }
);
