import { HorizontalAlignment, VerticalAlignment } from "./Popup.types";
import { elementScroll } from "./element-scroll";

export function relativePosition(
  related: HTMLElement,
  alignH: HorizontalAlignment,
  alignV: VerticalAlignment,
  isParent?: boolean
) {
  let relatedRect = related.getBoundingClientRect();
  const scroll = elementScroll(related);
  const rect = {
    x: relatedRect.x,
    y: relatedRect.y,
    width: relatedRect.width,
    height: relatedRect.height,
    left: relatedRect.left + scroll.left,
    right: relatedRect.right + scroll.left,
    top: relatedRect.top + scroll.top,
    bottom: relatedRect.bottom + scroll.top,
  } as DOMRect;

  let left, top, right, bottom;
  const { width, height } = rect;
  switch (alignH) {
    case "left":
      left = isParent ? 0 : rect.left;
      break;
    case "right":
      left = isParent ? width : rect.right;
      break;
    case "center":
      left = isParent ? width / 2 : rect.left + width / 2;
      break;
    case "inner-left":
      left = isParent ? 0 : rect.left;
      break;
    case "inner-right":
      right = isParent ? 0 : rect.right;
      break;
  }
  switch (alignV) {
    case "top":
      top = isParent ? 0 : rect.top;
      break;
    case "bottom":
      top = isParent ? height : rect.bottom;
      break;
    case "middle":
      top = isParent ? height / 2 : rect.top + height / 2;
      break;
    case "inner-top":
      top = isParent ? 0 : rect.top;
      break;
    case "inner-bottom":
      bottom = isParent ? 0 : rect.bottom;
      break;
  }
  return { top, left, right, bottom, width, height };
}
