import React, { forwardRef } from "react";
import { IconProps } from "./Icon.types";
import { StyledIcon } from "./styled";

export const Icon = forwardRef<HTMLElement, IconProps>((props, ref) => {
  const { name, title } = props;
  return (
    <StyledIcon ref={ref} {...props} title={title || ""} data-iconname={name} />
  );
});
