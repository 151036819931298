import { LocationProps, Metrics } from "abpi-common";
import { useEffect, useState } from "react";
import { getMetricLocations } from "../components/map/options";

export function useMetricLocations() {
  const [currentMetric, setCurrentMetric] = useState(Metrics[0]);
  const [availableLocations, setAvailableLocations] = useState<LocationProps[]>(
    []
  );
  const [currentLocation, setCurrentLocation] = useState<LocationProps>();

  useEffect(() => {
    const locations = getMetricLocations(currentMetric);
    setAvailableLocations(locations);
    currentLocation &&
      !locations.includes(currentLocation) &&
      setCurrentLocation(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMetric]);

  return {
    currentMetric,
    currentLocation,
    availableLocations,
    setCurrentMetric,
    setCurrentLocation,
  };
}
