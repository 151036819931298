import { AtlasMapValues, AtlasValues } from "@hokuto/jam-core";
import { JamBaseProps } from "@hokuto/jam-ui";
import {
  MetricSchema,
  Locations,
  isMetricAvailable,
  IrelandMapId,
  LocationProps,
} from "abpi-common";
import { JAM_ARGS } from "../../const";

export const atlasCountries: AtlasMapValues = {
  mapId: Locations.Countries.mapId,
  precision: 0,
};

export const atlasRegions: AtlasMapValues = {
  mapId: Locations.Regions.mapId,
  precision: 300,
  removePrevious: [Locations.Countries.mapId],
};

export const atlasWpcs: AtlasMapValues = {
  mapId: Locations.Wpcs.mapId,
  precision: 1500,
  removePrevious: [Locations.Regions.mapId],
};

export const altasSchema: AtlasValues = {
  defaultPrecision: 0,
  maps: [],
};

export function getMetricLocations(metric: MetricSchema) {
  const { groupType } = metric;
  const locations: LocationProps[] = [];
  if (isMetricAvailable(Locations.Countries, groupType))
    locations.push(Locations.Countries);
  if (isMetricAvailable(Locations.Regions, groupType))
    locations.push(Locations.Regions);
  if (isMetricAvailable(Locations.Wpcs, groupType))
    locations.push(Locations.Wpcs);
  return locations;
}

export function getAtlasProps(metric: MetricSchema, location?: LocationProps) {
  const { sites } = metric;

  const atlasProps: JamBaseProps = {
    ...JAM_ARGS,
    atlas: { defaultPrecision: 0, maps: [] },
  };
  atlasProps.atlas!.maps.push({ mapId: IrelandMapId, precision: 0 });
  const locations = getMetricLocations(metric);
  if (location) {
    if (location === Locations.Countries)
      atlasProps.atlas!.maps.push({ mapId: atlasCountries.mapId });
    else if (location === Locations.Regions)
      atlasProps.atlas!.maps.push({ mapId: atlasRegions.mapId });
    else if (location === Locations.Wpcs)
      atlasProps.atlas!.maps.push({ mapId: atlasWpcs.mapId });
  } else {
    locations.includes(Locations.Countries) &&
      atlasProps.atlas!.maps.push(atlasCountries);
    locations.includes(Locations.Regions) &&
      atlasProps.atlas!.maps.push(atlasRegions);
    locations.includes(Locations.Wpcs) &&
      atlasProps.atlas!.maps.push(atlasWpcs);
  }
  if (sites) atlasProps.atlas!.maps.push({ mapId: sites.mapId, precision: 0 });

  return atlasProps;
}
