import { PaginationProps } from "./Table.types";
import {
  StyledPagination,
  StyledNoData,
  StyledPaginationButton,
} from "./styled";
import { VariantProps } from "./../../types/theme.types";
import { Icon } from "./../icon/main";

export function Pagination(args: PaginationProps & VariantProps) {
  const {
    totalCount,
    pageSize = 50,
    currentPage = 1,
    showCount = 2,
    variant,
    onChange,
  } = args;
  const totalPages = Math.ceil(totalCount / pageSize);
  let minPage = Math.max(currentPage - showCount, 1);
  let maxPage = Math.min(minPage + showCount * 2, totalPages);
  minPage = Math.max(maxPage - showCount * 2, 1);
  const pages = [];
  while (minPage <= maxPage) {
    pages.push(minPage);
    minPage++;
  }

  return !totalCount ? (
    <StyledNoData>No Data Available</StyledNoData>
  ) : (
    <StyledPagination data-testid="pagination">
      <StyledPaginationButton
        outlined
        noBorder
        variant={variant}
        disabled={currentPage <= 1}
        onClick={() => onChange && onChange(currentPage - 1)}
      >
        <Icon name="chevron" rotate="left" />
      </StyledPaginationButton>
      {pages[0] !== 1 && (
        <>
          <StyledPaginationButton
            outlined={currentPage !== 1}
            noBorder={currentPage !== 1}
            variant={variant}
            onClick={() => onChange && onChange(1)}
          >
            1
          </StyledPaginationButton>
          {"\u2026"}
        </>
      )}
      {pages.map((num) => {
        return (
          <StyledPaginationButton
            key={num}
            outlined={currentPage !== num}
            noBorder={currentPage !== num}
            variant={variant}
            onClick={() => onChange && onChange(num)}
          >
            {num}
          </StyledPaginationButton>
        );
      })}
      {pages[pages.length - 1] !== totalPages && (
        <>
          {"\u2026"}
          <StyledPaginationButton
            outlined={currentPage !== totalPages}
            noBorder={currentPage !== totalPages}
            variant={variant}
            onClick={() => onChange && onChange(totalPages)}
          >
            {totalPages}
          </StyledPaginationButton>
        </>
      )}
      <StyledPaginationButton
        outlined
        noBorder
        variant={variant}
        disabled={currentPage >= totalPages}
        onClick={() => onChange && onChange(currentPage + 1)}
      >
        <Icon name="chevron" rotate="right" />
      </StyledPaginationButton>
    </StyledPagination>
  );
}
