import { AtlasMapResponse, Void } from "@hokuto/jam-core";
import { JamApi, ParamsObserver, useApi } from "@hokuto/jam-ui";
import { useEffect, useState } from "react";
import { MapFeatureRel, MetricValue } from "../components/map/Map.types";

export function useParamsObserver(args: {
  onChange: (params: AtlasMapResponse[]) => void;
  onMove: (mapFeature?: MapFeatureRel) => void;
  onClick: (data?: MetricValue) => void;
  data?: MetricValue[];
}) {
  const { onChange, onMove, onClick, data } = args;
  const [error, setError] = useState<Error>();
  const changeParamsApi = useApi<AtlasMapResponse[]>();
  const moveParamsApi = useApi<AtlasMapResponse[]>();
  const clickParamsApi = useApi<AtlasMapResponse[]>();

  const [paramsObserver] = useState(
    new ParamsObserver({
      waitMove: 50,
      onError: setError,
      onClick: ({ atlasParams }) => {
        atlasParams &&
          clickParamsApi.setDataRequest(
            JamApi.Atlas.Search.ViewBox.Items(atlasParams)
          );
      },
      onMove: ({ atlasParams }) => {
        atlasParams &&
          moveParamsApi.setDataRequest(
            JamApi.Atlas.Search.ViewBox.Items(atlasParams)
          );
      },
      onChange: ({ atlasParams }) => {
        setError(undefined);
        atlasParams &&
          changeParamsApi.setDataRequest(
            JamApi.Atlas.Search.ViewBox.Items({
              ...atlasParams,
              search: {
                f: ["id", "properties", "area", "centroid"],
              },
            })
          );
      },
    })
  );

  useEffect(() => {
    changeParamsApi.dataRequest
      ?.then(onChange, Void)
      .catch((err) => console.error(err));
  }, [changeParamsApi.dataRequest]);

  useEffect(() => {
    moveParamsApi.dataRequest
      ?.then((maps) => {
        if (!data) return;
        let selectedData: MetricValue | undefined = undefined;
        const [map] = maps;
        if (!map?.features) return onMove();
        for (const feature of map.features) {
          selectedData = data.find(
            (item) => item.featureId === feature.id && item.mapId === map.id
          );
          if (selectedData) break;
        }
        onMove(selectedData);
      }, Void)
      .catch((e) => console.error(e));
  }, [moveParamsApi.dataRequest]);

  useEffect(() => {
    clickParamsApi.dataRequest
      ?.then((maps) => {
        if (!data) return;
        let selectedData: MetricValue | undefined = undefined;
        for (const map of maps) {
          if (!map.features) continue;
          for (const feature of map.features) {
            selectedData = data.find(
              (item) => item.featureId === feature.id && item.mapId === map.id
            );
            if (selectedData) break;
          }
          if (selectedData) break;
        }
        onClick(selectedData);
      }, Void)
      .catch((e) => console.error(e));
  }, [clickParamsApi.dataRequest]);

  return { paramsObserver, error };
}
