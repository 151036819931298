import { CardProps, CardTabs } from "./Card.types";
import { useLevel } from "../../hooks/level";
import { Button } from "../button/main";
import { Icon } from "../icon/main";
import { StyledCardHeader, StyledSideReportButton } from "./styled";
import { dowloadReport } from "../../downolad";
import { Locations } from "abpi-common";
import { CardMetrics } from "./metrics";
import { useEffect, useState } from "react";
import { CardSites } from "./sites";

export function Quartile(
  index: number,
  value: number | string,
  current: number
) {
  let text = (
    <>
      Q{index}: {value}
    </>
  );
  if (current === index) text = <b>{text}</b>;
  return text;
}

export function Card(args: CardProps) {
  const {
    selectedData,
    levelType,
    onClose,
    setPin,
    currentSites,
    currentMetric,
  } = args;
  const { results } = useLevel(selectedData, levelType);
  const [currentTab, setCurrentTab] = useState<CardTabs>(CardTabs.Metrics);
  const { name } = selectedData;

  const sitesCount = currentSites?.reduce(
    (count, sites) => count + sites.values.length,
    0
  );

  useEffect(() => {
    currentSites && !sitesCount && setCurrentTab(CardTabs.Metrics);
  }, [currentSites, sitesCount]);

  return (
    <>
      <StyledCardHeader>
        {onClose && (
          <small onClick={onClose}>
            Back to List
            <Icon name="chevron" rotate="right" />
          </small>
        )}
        <h3>{name}</h3>
        {setPin && levelType === Locations.Wpcs && (
          <small
            onClick={() => {
              const area = Math.min(selectedData.area, 10);
              setPin({
                coords: selectedData.centroid,
                radius: Math.pow(area, 1 / 2),
              });
            }}
          >
            Navigate
            <Icon name="close-surrounding" />
          </small>
        )}
      </StyledCardHeader>

      <div>
        <Button
          variant={currentTab === CardTabs.Metrics ? "tertiary" : "off"}
          onClick={() => setCurrentTab(CardTabs.Metrics)}
        >
          {CardTabs.Metrics}
        </Button>
        <Button
          variant={
            currentTab === CardTabs.Sites
              ? "tertiary"
              : sitesCount
              ? "off"
              : "disabled"
          }
          onClick={() => sitesCount && setCurrentTab(CardTabs.Sites)}
        >
          {CardTabs.Sites}
        </Button>
      </div>
      {currentTab === CardTabs.Metrics && (
        <CardMetrics
          metrics={results}
          levelType={levelType}
          currentMetric={currentMetric}
          key={selectedData.levelId}
        />
      )}
      {currentTab === CardTabs.Sites && <CardSites sites={currentSites} />}
      <StyledSideReportButton
        variant="primary"
        disabled={!selectedData}
        onClick={() => selectedData && dowloadReport(selectedData)}
      >
        Export Report
      </StyledSideReportButton>
    </>
  );
}
