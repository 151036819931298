import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { LightTheme } from "./theme/light";
import { GlobalFonts } from "./styles/fonts";
import { MainStyles } from "./styles/main";
import { App } from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={LightTheme}>
    <GlobalFonts />
    <MainStyles />
    <App />
  </ThemeProvider>
);
