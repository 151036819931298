import { JamApi, jsonFetch, useApi } from "@hokuto/jam-ui";
import {
  LevelResults,
  LevelValue,
  LocationProps,
  getAvailableMetrics,
} from "abpi-common";
import { MetricValue } from "../components/map/Map.types";
import { MapFeatureResponse } from "@hokuto/jam-core";
import { useEffect, useState } from "react";
import { JAM_ARGS } from "../const";

export function useLevel(metric: MetricValue, levelType?: LocationProps) {
  const { featureId, mapId } = metric;
  const featureApi = useApi<MapFeatureResponse[]>();
  const [levelId, setLevelId] = useState<string>();
  const [results, setResults] = useState<LevelResults[]>();

  useEffect(() => {
    featureApi.setDataRequest(
      JamApi.Map.Search.Feature.Items({
        ...JAM_ARGS,
        mapId,
        search: { f: ["properties"], q: [featureId], qa: ["id"], limit: 1 },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureId, mapId]);

  useEffect(() => {
    featureApi.dataRequest?.then(([feature]) => {
      if (!levelType) return;
      if (!feature) return;
      const { properties } = feature;
      const { idProp } = levelType;
      const id = properties![idProp] as string;
      setLevelId(id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureApi.dataRequest]);

  useEffect(() => {
    if (!levelType) return;
    if (!levelId) return;
    const availableMetrics = getAvailableMetrics(levelType);
    Promise.all(
      availableMetrics.map((metric) => {
        return jsonFetch<LevelValue | null>(
          `${process.env.REACT_APP_ABPI_SERVER}/${metric.urlPrefix}/${levelType.urlSuffix}/${levelId}`
        )
          .catch((e) => {
            return Promise.resolve(null);
          })
          .then((data) => {
            if (!data) return null;
            return { ...data, metric };
          });
      })
    ).then((results) => {
      const availableResults = results.filter(
        (item) => !!item
      ) as LevelResults[];

      setResults(availableResults);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelId]);

  return { results };
}
