import { createRef } from "react";
import { SearchProps, SearchResultsProps } from "./Search.types";
import {
  StyledInputContainer,
  StyledInput,
  IconRight,
  StyledSearchResults,
} from "./styled";
import { Popup } from "../popup/main";

export function Search(args: SearchProps) {
  const searchRef = createRef<HTMLDivElement>();
  const inputRef = createRef<HTMLInputElement>();
  const { children, variant, inputProps, onPopupClose, ...props } = args;

  const { disabled, name = "textfield" } = props;

  const variantName = disabled ? "disabled" : variant;

  return (
    <StyledInputContainer
      ref={searchRef}
      data-testid="input-container"
      disabled={disabled}
      variant={variantName}
      {...props}
    >
      <IconRight
        name={children ? "close" : "search"}
        data-testid="input-icon-right"
        onClick={() => inputRef.current && (inputRef.current.value = "")}
      />
      <StyledInput
        id={name}
        ref={inputRef}
        variant={variantName}
        data-testid="input"
        {...inputProps}
      />
      {children && (
        <Popup
          relatedRef={searchRef}
          relatedParent
          alignH="center"
          alignV="bottom"
          observe="width"
          dismissible
          onClose={onPopupClose}
        >
          {children}
        </Popup>
      )}
    </StyledInputContainer>
  );
}

export function SearchResults(args: SearchResultsProps) {
  const { searchResults, onSelection, variant, ...props } = args;
  if (!searchResults) return <></>;
  return (
    <StyledSearchResults variant={variant} {...props}>
      <h3>
        {searchResults && !searchResults.length ? "No results" : "Results:"}
      </h3>
      {searchResults?.length ? (
        <ul>
          {searchResults?.map((item, i) => {
            return (
              <li
                key={i}
                onClick={() => {
                  onSelection(item);
                }}
              >
                {item.display_name}
              </li>
            );
          })}
        </ul>
      ) : null}
    </StyledSearchResults>
  );
}
