import { MetricRequest } from "abpi-common";
import { blobFetch } from "@hokuto/jam-ui";
import { JAM_ARGS } from "./const";

export function dowloadReport(metric: MetricRequest) {
  blobFetch(`${process.env.REACT_APP_ABPI_SERVER}/export`, {
    method: "POST",
    params: {
      ...JAM_ARGS,
      ...metric,
    },
  }).then((blob) => {
    let { name } = metric;
    name = name.replace(/ /g, "-");
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `abpi_insight_report-${name}.pdf`);
    link.click();
  });
}
