import { MetricsSelectionSchema } from "./Metrics.types";
import { StyledMetricsSelection } from "./styled";
import { EconomyLogo } from "./economy";
import { HealthLogo } from "./health";
import { ResearchLogo } from "./research";
import {
  EconomyMetrics,
  PartnershipsMetrics,
  ResearchMetrics,
} from "abpi-common";
import { MetricsDropdown } from "./dropdown";

export function MetricSelection(args: MetricsSelectionSchema) {
  const { selected, onChange } = args;

  return (
    <>
      <StyledMetricsSelection>
        <MetricsDropdown
          label={"Economy"}
          selected={selected}
          options={EconomyMetrics}
          onChange={onChange}
        >
          <EconomyLogo />
        </MetricsDropdown>
        <MetricsDropdown
          label={"NHS/Industry collaborations"}
          selected={selected}
          options={PartnershipsMetrics}
          onChange={onChange}
        >
          <HealthLogo />
        </MetricsDropdown>
        <MetricsDropdown
          label={"Research"}
          selected={selected}
          options={ResearchMetrics}
          onChange={onChange}
        >
          <ResearchLogo />
        </MetricsDropdown>
      </StyledMetricsSelection>
    </>
  );
}
