import { LogoSchema } from "./Metrics.types";
import { useLogo } from "../../hooks/logo";

export function ResearchLogo(args: LogoSchema) {
  const { props, mainColor, foreColor } = useLogo(args);
  return (
    <svg
      viewBox="0 0 116 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 83.7575 35.8135 C 93.4328 46.7403 92.3332 63.5144 81.3042 73.2801 C 70.2752 83.0458 53.4914 82.1067 43.8161 71.1798 C 36 63 34 52 35 50"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M77.8038 46.7607L72.6327 41.5396L59.3432 54.9563L55.3546 50.9313L50.188 56.1479L59.2841 65.4487L77.8038 46.7607Z"
        stroke={mainColor}
        strokeWidth="1.5"
      />
      <path
        d="M56.4633 33.2025V25.3451L49.5625 24.7645C49.0818 22.1945 48.1442 19.7816 46.8402 17.6257L51.4471 12.1098L45.9598 6.55108L40.6771 11.0771C38.6069 9.65406 36.2749 8.59276 33.7668 7.98359L33.1719 0.782959H25.4097L24.8338 7.76943C22.2924 8.25963 19.9176 9.20195 17.7855 10.525L12.3409 5.861L6.85364 11.4197L11.3272 16.769C9.91851 18.8631 8.87152 21.2236 8.27187 23.765L1.16162 24.3647V32.2268L8.05767 32.8075C8.53835 35.3774 9.47595 37.7903 10.78 39.9415L6.17305 45.4574L11.6604 51.0161L16.9431 46.4901C19.0133 47.9131 21.3453 48.9744 23.8534 49.5836L24.4483 56.7842H32.2105L32.7864 49.7977C35.3231 49.3075 37.7026 48.3605 39.8347 47.0422L45.2793 51.7109L50.7665 46.1522L46.2977 40.8029C47.7064 38.7089 48.7487 36.3435 49.3531 33.8069L56.4633 33.2072V33.2025Z"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M13.9448 28.7812C13.9448 20.4621 20.6029 13.7231 28.8173 13.7231"
        stroke={mainColor}
        strokeWidth="1.5"
      />
      <path
        d="M37.4219 28.7812C37.4219 33.5975 33.5669 37.5 28.8125 37.5C24.0581 37.5 20.2031 33.5975 20.2031 28.7812C20.2031 23.9649 24.0581 20.0624 28.8125 20.0624C33.5669 20.0624 37.4219 23.9649 37.4219 28.7812Z"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M103.965 35.5509C106.205 33.1202 107.571 29.889 107.571 26.3396C107.571 18.7879 101.39 12.6658 93.7674 12.6658C86.1447 12.6658 79.9639 18.7879 79.9639 26.3396C79.9639 30.0554 81.4595 33.4212 83.8878 35.8862"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M83.9941 38.1246L83.9941 45.4293C83.9941 50.6672 88.2919 54.9053 93.5895 54.9053C98.887 54.9053 103.185 50.6597 103.185 45.4293L103.185 38.1246L83.9941 38.1246Z"
        fill="white"
        stroke={mainColor}
        strokeWidth="1.5"
      />
      <path
        d="M112.94 26.8223C112.94 16.2995 104.41 7.76917 93.8871 7.76917C83.3644 7.76917 74.834 16.2995 74.834 26.8223"
        stroke={mainColor}
        strokeWidth="3"
        strokeDasharray="1.15 9.23"
      />
    </svg>
  );
}
