import { StyledTable } from "./styled";
import { DataType, TableProps, TableRowType } from "./Table.types";
import { TableBodyCells } from "./body-cells";
import { TableHeadCells } from "./head-cells";
import { TableRow } from "./table-row";

export function Table<T extends DataType>(args: TableProps<T>) {
  const {
    meta,
    data,
    selected,
    hover,
    hideHeader,
    useRowVariant,
    onRowClick,
    onRowHover,
    children,
    ...props
  } = args;
  const { variant } = props;

  return (
    <StyledTable {...props}>
      {!hideHeader && (
        <thead>
          <TableRow<T> rowType={TableRowType.Head} variant={variant}>
            {children || <TableHeadCells meta={meta} />}
          </TableRow>
        </thead>
      )}
      <tbody>
        {data &&
          data.map((values, i) => {
            const props = {
              key: i,
              values,
              variant,
              hover: values === hover,
              selected: !!selected?.includes(values),
              onMouseOver:
                onRowHover &&
                function () {
                  onRowHover(values);
                },
              onClick:
                onRowClick &&
                function () {
                  onRowClick(values);
                },
            };
            return (
              <TableRow<T>
                {...props}
                rowType={TableRowType.Body}
                variant={useRowVariant ? variant : undefined}
              >
                {children || <TableBodyCells meta={meta} values={values} />}
              </TableRow>
            );
          })}
      </tbody>
    </StyledTable>
  );
}
