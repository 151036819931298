import { useEffect, useState } from "react";
import { jsonFetch } from "@hokuto/jam-ui";
import {
  Sites,
  SiteResults,
  SiteSchema,
  LocationProps,
  MetricSchema,
} from "abpi-common";
import { MetricValue } from "../components/map/Map.types";

export interface SitesLocationSchema {
  type: SiteSchema;
  values: SiteResults[];
}

export function useSites(args: {
  currentMetric: MetricSchema;
  selectedData?: MetricValue;
  levelType?: LocationProps;
}) {
  const { levelType, selectedData, currentMetric } = args;
  const [allSites, loadSites] = useState<SitesLocationSchema[]>();
  const [curretSites, setCurrentSites] = useState<SitesLocationSchema[]>();

  useEffect(() => {
    Promise.all(
      Sites.map((type) => {
        return jsonFetch<SiteResults[]>(
          `${process.env.REACT_APP_ABPI_SERVER}/${type.urlPrefix}`
        ).then((values) => {
          return Promise.resolve({ values, type });
        });
      })
    ).then(loadSites);
  }, []);

  useEffect(() => {
    const groupType = levelType && levelType[currentMetric.groupType]!;
    if (!allSites || !selectedData || !groupType)
      return setCurrentSites(undefined);

    const currentSites = allSites.map((item) => {
      const { values: siteValues, type } = item;
      const values = siteValues.filter(
        (item) => item[groupType] === selectedData.levelId
      );
      return { values, type };
    });

    setCurrentSites(currentSites);
  }, [allSites, currentMetric, selectedData, levelType]);

  return curretSites;
}
