import {
  PrimaryColor,
  SelectedBackColor,
  SelectedForeColor,
  TextColor,
} from "abpi-common";
import { DefaultTheme } from "styled-components";

export const LightTheme: DefaultTheme = {
  name: "light",
  mode: "light",
  fontSize: 16,
  sizes: {
    xs: 0,
    sm: 300,
    md: 660,
    lg: 980,
    xl: 1400,
  },
  textColor: TextColor,
  bgColor: "#FFF",
  variantColors: {
    primary: { text: PrimaryColor, bg: "bgColor" },
    secondary: { text: "#0F768F", bg: "bgColor" },
    tertiary: { text: SelectedForeColor, bg: SelectedBackColor },
    quaternary: { text: "#1A5767", bg: "bgColor" },
    off: { text: "#E9E9E9", bg: "textColor" },
    disabled: { text: "#7B7B7B", bg: "bgColor" },
    success: { text: "#b1d59a", bg: "textColor" },
    warning: { text: "#f7ce84", bg: "textColor" },
    error: { text: "#ed4c4c", bg: "bgColor" },
  },
};
