import {
  StyledColorScale,
  StyledColorScaleLabelSelected,
  StyledColorScaleStep,
} from "./styled";

export function ColorScale(args: { steps: string[] }) {
  const { steps, ...props } = args;
  return (
    <StyledColorScale {...props}>
      {steps.map((color, i) => {
        return (
          <StyledColorScaleStep key={i} color={color}></StyledColorScaleStep>
        );
      })}
      <StyledColorScaleLabelSelected />
    </StyledColorScale>
  );
}
