import React from "react";
import { createGlobalStyle, css } from "styled-components";

export interface FontProps {
  dirName?: string;
}

export const IconFont = createGlobalStyle<FontProps>`
${({ dirName }) => {
  return css`
    @font-face {
      font-family: "iconfont";
      src: url("${dirName}/iconfont/iconfont.eot") format("embedded-opentype"),
        url("${dirName}/iconfont/iconfont.woff2") format("woff2"),
        url("${dirName}/iconfont/iconfont.woff") format("woff");
    }
  `;
}}
`;

export const GilroyFont = createGlobalStyle<FontProps>`
${({ dirName }) => {
  return css`
    @font-face {
      font-family: "Gilroy";
      src: url("${dirName}/gilroy/Gilroy-Regular.eot");
      src: url("${dirName}/gilroy/Gilroy-Regular.eot?#iefix")
          format("embedded-opentype"),
        url("${dirName}/gilroy/Gilroy-Regular.woff") format("woff"),
        url("${dirName}/gilroy/Gilroy-Regular.ttf") format("truetype");
      font-style: normal;
      font-display: swap;
    }
  `;
}}
`;

export function GlobalFonts({ dirName = "fonts", ...props }: FontProps) {
  props = { dirName, ...props };
  return (
    <>
      <IconFont {...props} />
      <GilroyFont {...props} />
    </>
  );
}
