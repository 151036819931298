import { scaleLinear } from "d3-scale";
import { interpolateRgb } from "d3-interpolate";
import { MetricTypes, RegionGroup, GroupType, WpcGroup, ValueType, SiteTypes, SiteLabelProp, MetricDescription, SiteLabel, LocationLabelProp, LocationCodeProp, } from "./types.js";
export * from "./types.js";
export const GBPFormatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    notation: "compact",
    compactDisplay: "short",
});
export const NumberFormatter = new Intl.NumberFormat("en-GB", {
    notation: "compact",
    compactDisplay: "short",
});
export const IrelandMapId = "655200d8a0cf8d33af427622";
export const MetricLinks = {
    CaseStudy: [
        {
            label: "Explore examples of NHS/Industry partnerships",
            href: "https://www.abpi.org.uk/partnerships/working-with-the-nhs/nhs-industry-partnership-case-studies-library",
        },
        {
            label: "Visit Disclosure UK for more information",
            href: "https://www.abpi.org.uk/reputation/disclosure-uk",
        },
    ],
};
export const Locations = {
    Countries: {
        idProp: LocationCodeProp.Country,
        labelProp: LocationLabelProp.Country,
        label: "Countries",
        [GroupType.Manufacturing]: WpcGroup.Country,
        [GroupType.ManufacturingPerCapita]: WpcGroup.Country,
        [GroupType.RD]: WpcGroup.Country,
        [GroupType.RDPerCapita]: WpcGroup.Country,
        [GroupType.PartnershipsNhs]: WpcGroup.Country,
        [GroupType.PartnershipsNhsPerCapita]: WpcGroup.Country,
        [GroupType.Jobs]: WpcGroup.Country,
        [GroupType.JobsPercent]: WpcGroup.Country,
        [GroupType.JobsPerCapita]: WpcGroup.Country,
        [GroupType.Contribution]: WpcGroup.Country,
        [GroupType.ContributionPerCapita]: WpcGroup.Country,
        [GroupType.Price]: WpcGroup.Country,
        [GroupType.PricePerCapita]: WpcGroup.Country,
        [GroupType.Trials]: RegionGroup.Country,
        [GroupType.TrialsPerCapita]: RegionGroup.Country,
        // mapId: "6482dbc4d37ec7fcfd4d2a95",
        mapId: "66671195a488db4ebeee171c",
        urlSuffix: "countries",
    },
    Regions: {
        idProp: LocationCodeProp.Region,
        labelProp: LocationLabelProp.Region,
        label: "Regions",
        [GroupType.Manufacturing]: WpcGroup.Region,
        [GroupType.ManufacturingPerCapita]: WpcGroup.Region,
        [GroupType.RD]: WpcGroup.Region,
        [GroupType.RDPerCapita]: WpcGroup.Region,
        [GroupType.PartnershipsNhs]: WpcGroup.Region,
        [GroupType.PartnershipsNhsPerCapita]: WpcGroup.Region,
        [GroupType.Jobs]: WpcGroup.Region,
        [GroupType.JobsPercent]: WpcGroup.Region,
        [GroupType.JobsPerCapita]: WpcGroup.Region,
        [GroupType.Contribution]: WpcGroup.Region,
        [GroupType.ContributionPerCapita]: WpcGroup.Region,
        [GroupType.Price]: WpcGroup.Region,
        [GroupType.PricePerCapita]: WpcGroup.Region,
        [GroupType.Trials]: RegionGroup.Region,
        [GroupType.TrialsPerCapita]: RegionGroup.Region,
        // mapId: "6481f36c6a85c66a2f397769",
        mapId: "66671196a488db4ebeee172c",
        urlSuffix: "regions",
    },
    Wpcs: {
        idProp: LocationCodeProp.Wpc,
        labelProp: LocationLabelProp.Wpc,
        label: "Constituencies",
        [GroupType.Manufacturing]: WpcGroup.Wpc,
        [GroupType.ManufacturingPerCapita]: WpcGroup.Wpc,
        [GroupType.RD]: WpcGroup.Wpc,
        [GroupType.RDPerCapita]: WpcGroup.Wpc,
        [GroupType.PartnershipsNhs]: WpcGroup.Wpc,
        [GroupType.PartnershipsNhsPerCapita]: WpcGroup.Wpc,
        [GroupType.Jobs]: WpcGroup.Wpc,
        [GroupType.JobsPercent]: WpcGroup.Wpc,
        [GroupType.JobsPerCapita]: WpcGroup.Wpc,
        [GroupType.Contribution]: WpcGroup.Wpc,
        [GroupType.ContributionPerCapita]: WpcGroup.Wpc,
        [GroupType.Price]: WpcGroup.Wpc,
        [GroupType.PricePerCapita]: WpcGroup.Wpc,
        // mapId: "6489c8c4e5165690579b4470",
        mapId: "6666f966a488db4ebeee02a7",
        urlSuffix: "wpcs",
    },
};
export const LocationValues = Object.values(Locations);
export function getLocationByMapId(mapId) {
    if (Locations.Countries.mapId === mapId)
        return Locations.Countries;
    if (Locations.Regions.mapId === mapId)
        return Locations.Regions;
    if (Locations.Wpcs.mapId === mapId)
        return Locations.Wpcs;
}
export function getLocation(prop, value) {
    return LocationValues.find((item) => item[prop] === value);
}
export const ManufacturingSites = {
    urlPrefix: SiteTypes.Manufacturing,
    mapId: "648214d3bb3e610261a1fc63",
    groupType: GroupType.Manufacturing,
    labelProp: SiteLabelProp.Manufacturing,
    label: SiteLabel.Manufacturing,
};
export const RDSites = {
    urlPrefix: SiteTypes.RD,
    mapId: "648b15d6f06d9c852032d91b",
    groupType: GroupType.RD,
    labelProp: SiteLabelProp.RD,
    label: SiteLabel.RD,
};
export const PartnershipsNhsSites = {
    urlPrefix: SiteTypes.PartnershipsNhs,
    mapId: "648c817e2fc048974a3d1e1c",
    groupType: GroupType.PartnershipsNhs,
    labelProp: SiteLabelProp.PartnershipsNhs,
    label: SiteLabel.PartnershipsNhs,
};
export const Sites = [
    ManufacturingSites,
    PartnershipsNhsSites,
    RDSites,
];
export const ManufacturingMetric = {
    label: SiteLabel.Manufacturing,
    description: MetricDescription.Manufacturing,
    valueType: ValueType.Count,
    groupType: GroupType.Manufacturing,
    urlPrefix: MetricTypes.Manufacturing,
    // mapId: "648214d3bb3e610261a1fc63",
    sites: ManufacturingSites,
    styleId: "64c12c00205f8d819373538f",
    // styleId: "655336f3613d4c2d6e2aa38e",
    formatter: NumberFormatter,
};
export const ManufacturingPerCapitaMetric = {
    label: `${SiteLabel.Manufacturing} per 100k population`,
    description: MetricDescription.Manufacturing,
    valueType: ValueType.PerCapita,
    labelValueType: true,
    groupType: GroupType.ManufacturingPerCapita,
    urlPrefix: MetricTypes.ManufacturingPerCapita,
    sites: ManufacturingSites,
    styleId: "64c12c00205f8d81937358c5",
    formatter: NumberFormatter,
    populationField: "population",
};
export const JobsMetric = {
    label: "Jobs",
    description: MetricDescription.Jobs,
    valueType: ValueType.Count,
    groupType: GroupType.Jobs,
    urlPrefix: MetricTypes.Jobs,
    styleId: "64c12c02205f8d8193738275",
    formatter: NumberFormatter,
};
export const JobsPerCapitaMetric = {
    label: "Jobs per 100k population",
    description: MetricDescription.Jobs,
    valueType: ValueType.PerCapita,
    labelValueType: true,
    groupType: GroupType.JobsPerCapita,
    urlPrefix: MetricTypes.JobsPerCapita,
    styleId: "64c12c03205f8d81937387ab",
    formatter: NumberFormatter,
    populationField: "population",
};
export const JobsPercentMetric = {
    label: "% Pharmaceutical Jobs",
    description: MetricDescription.JobsPercent,
    valueType: ValueType.Percent,
    groupType: GroupType.JobsPercent,
    urlPrefix: MetricTypes.JobsPercent,
    styleId: "64c12c03205f8d8193738ce1",
    formatter: NumberFormatter,
    totalField: "total_observations",
};
export const PriceMetric = {
    label: "Value added through employment in your area",
    description: MetricDescription.Jobs,
    valueType: ValueType.GBP,
    groupType: GroupType.Price,
    urlPrefix: MetricTypes.Price,
    styleId: "64c12c0c205f8d8193739cc7",
    formatter: GBPFormatter,
};
export const PricePerCapitaMetric = {
    label: "Value added through employment in your area per 100k population",
    description: MetricDescription.Jobs,
    valueType: ValueType.PerCapita,
    labelValueType: true,
    groupType: GroupType.PricePerCapita,
    urlPrefix: MetricTypes.PricePerCapita,
    styleId: "64c12c0c205f8d8193739ce9",
    formatter: GBPFormatter,
    populationField: "population",
};
export const ParthenrshipsNhsMetric = {
    label: SiteLabel.PartnershipsNhs,
    description: MetricDescription.Partnerships,
    links: MetricLinks.CaseStudy,
    valueType: ValueType.Count,
    groupType: GroupType.PartnershipsNhs,
    urlPrefix: MetricTypes.PartnershipsNhs,
    sites: PartnershipsNhsSites,
    styleId: "64c12c01205f8d8193736867",
    formatter: NumberFormatter,
};
export const ParthenrshipsNhsPerCapitaMetric = {
    label: `${SiteLabel.PartnershipsNhs} per 100k population`,
    description: MetricDescription.Partnerships,
    links: MetricLinks.CaseStudy,
    valueType: ValueType.PerCapita,
    groupType: GroupType.PartnershipsNhsPerCapita,
    urlPrefix: MetricTypes.PartnershipsNhsPerCapita,
    sites: PartnershipsNhsSites,
    styleId: "64c12c01205f8d8193736d9d",
    formatter: NumberFormatter,
    populationField: "population",
};
export const ContributionMetric = {
    label: "Value generated for NHS organisations through industry collaborations",
    description: MetricDescription.Partnerships,
    links: MetricLinks.CaseStudy,
    valueType: ValueType.GBP,
    groupType: GroupType.Contribution,
    urlPrefix: MetricTypes.Contribution,
    styleId: "64c12c03205f8d8193739217",
    formatter: GBPFormatter,
};
export const ContributionPerCapitaMetric = {
    label: "Value generated for NHS organisations through industry collaborations per 100k population",
    description: MetricDescription.Partnerships,
    links: MetricLinks.CaseStudy,
    valueType: ValueType.PerCapita,
    labelValueType: true,
    groupType: GroupType.ContributionPerCapita,
    urlPrefix: MetricTypes.ContributionPerCapita,
    styleId: "64c12c03205f8d819373974d",
    formatter: GBPFormatter,
    populationField: "population",
};
export const RDMetric = {
    label: SiteLabel.RD,
    description: MetricDescription.Research,
    valueType: ValueType.Count,
    groupType: GroupType.RD,
    urlPrefix: MetricTypes.RD,
    sites: RDSites,
    styleId: "64c12c00205f8d8193735dfb",
    formatter: NumberFormatter,
};
export const RDPerCapitaMetric = {
    label: `${SiteLabel.RD}  per 100k population`,
    description: MetricDescription.Research,
    valueType: ValueType.PerCapita,
    labelValueType: true,
    groupType: GroupType.RDPerCapita,
    urlPrefix: MetricTypes.RDPerCapita,
    sites: RDSites,
    styleId: "64c12c01205f8d8193736331",
    formatter: NumberFormatter,
    populationField: "population",
};
export const TrialsMetric = {
    label: "Clinical trial enrolment by site location",
    description: MetricDescription.Trials,
    valueType: ValueType.Count,
    groupType: GroupType.Trials,
    urlPrefix: MetricTypes.Trials,
    styleId: "64c12c0b205f8d8193739c83",
    formatter: NumberFormatter,
};
export const TrialsPerCapitaMetric = {
    label: "Clinical trial enrolment by site location per 100k population",
    description: MetricDescription.Trials,
    valueType: ValueType.PerCapita,
    labelValueType: true,
    groupType: GroupType.TrialsPerCapita,
    urlPrefix: MetricTypes.TrialsPerCapita,
    styleId: "64c12c0c205f8d8193739ca5",
    formatter: NumberFormatter,
    populationField: "population",
};
export const EconomyMetrics = [
    ManufacturingMetric,
    ManufacturingPerCapitaMetric,
    JobsMetric,
    JobsPerCapitaMetric,
    JobsPercentMetric,
    PriceMetric,
    PricePerCapitaMetric,
];
export const PartnershipsMetrics = [
    ParthenrshipsNhsMetric,
    ParthenrshipsNhsPerCapitaMetric,
    // CaseStudyMetric,
    ContributionMetric,
    ContributionPerCapitaMetric,
];
export const ResearchMetrics = [
    RDMetric,
    RDPerCapitaMetric,
    // ParthenrshipsOthersMetric,
    // ParthenrshipsOthersPerCapitaMetric,
    TrialsMetric,
    TrialsPerCapitaMetric,
];
export const Metrics = EconomyMetrics.concat(PartnershipsMetrics).concat(ResearchMetrics);
export function isMetricAvailable(location, groupType) {
    return Object.keys(location).includes(groupType);
}
export function getAvailableMetrics(location) {
    return Metrics.filter((item) => {
        return isMetricAvailable(location, item.groupType);
    });
}
export const ColorScheme = [
    "#59aed0",
    "#80bbd4",
    "#a8d2e2",
    "#b5c38d",
    "#77c588",
    "#549562",
];
export const TextColor = "#331F4C";
export const PrimaryColor = "#951B81";
export const SelectedForeColor = "#CA2C87";
export const SelectedBackColor = "#FFFFFF";
export const OffColor = "#E9E9E9";
export function getLinearGroupRange(firstValue, ...otherValues) {
    const values = [firstValue, ...otherValues];
    if (!firstValue)
        return;
    let min = undefined;
    let max = undefined;
    for (const i in values) {
        const { value } = values[i];
        min = min === undefined ? value : Math.min(min, value);
        max = max === undefined ? value : Math.max(max, value);
    }
    return [min, max];
}
export function calculateDomain(colorScheme, min = 0, max = 0) {
    const portion = (max - min) / (colorScheme.length - 1);
    const domain = colorScheme.map((_, i) => {
        return min + portion * i;
    });
    return domain;
}
export function getLinearColorScale(colorScheme, minVal = 0, maxVal = 100) {
    const dataDomain = [minVal, maxVal];
    // function calculateDomain(min = 0, max = 0) {
    //   const portion = (max - min) / (colorScheme.length - 1);
    //   const domain = colorScheme.map((_, i) => {
    //     return min + portion * i;
    //   });
    //   return domain;
    // }
    return scaleLinear()
        .domain(calculateDomain(colorScheme, ...dataDomain))
        .range(colorScheme)
        .interpolate(interpolateRgb.gamma(2.2));
}
// export function getQuantilesGroupRange(count: number, values: GroupResults[]) {
// }
export function getQuantileColorScale(colorScheme, values) {
    values = values
        .filter((item) => !!item.value)
        .sort((a, b) => {
        return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
    });
    const [firstValue, ...otherValues] = values;
    if (!firstValue)
        return;
    let step = Math.floor(values.length / (colorScheme.length - 1));
    if (step < 2) {
        const range = getLinearGroupRange(firstValue, ...otherValues);
        return range && getLinearColorScale(colorScheme, ...range);
    }
    let quantiles = [];
    let counter = 0;
    const last_item = values[values.length - 1];
    for (const i in values) {
        if (quantiles.length === colorScheme.length)
            break;
        const item = values[i];
        const colors = colorScheme.slice(quantiles.length);
        if (colors.length === 1) {
            quantiles.push(last_item.value);
            continue;
        }
        const { value } = item;
        const index = parseInt(i);
        if (index === counter) {
            quantiles.push(value);
            counter += step;
        }
    }
    return scaleLinear()
        .domain(quantiles)
        .range(colorScheme)
        .interpolate(interpolateRgb.gamma(2.2));
}
export function getColorScaleSteps(colorScale, steps) {
    const domain = colorScale.domain();
    let min = domain[0];
    const max = domain[domain.length - 1];
    const portion = (max - min) / steps;
    const results = [];
    while (min <= max) {
        min = Math.min(min, max);
        results.push(colorScale(min));
        min += portion;
    }
    return results;
}
export const defaultLinearColorScale = getLinearColorScale(ColorScheme);
export const defaultColorScaleSteps = getColorScaleSteps(defaultLinearColorScale, 25);
