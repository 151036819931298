import { useTheme } from "styled-components";
import { Const, JamCanvasMap, JamCanvasFeature } from "@hokuto/jam-ui";
import {
  lightenVariantTextColor,
  variantTextColor,
} from "../../theme/variants";

export function MapDrawer(
  args: Parameters<typeof JamCanvasMap>["0"]
): ReturnType<typeof JamCanvasMap> {
  const { atlasParams, options = {} } = args;
  if (!atlasParams) return null;
  const { scale } = atlasParams;
  const pointRadius = Math.max(1, Math.min(scale, 15));
  const strokeWidth = Math.max(0.5, Math.min(scale / 5, 2));

  const defaultStyle = { pointRadius, strokeWidth };

  return (
    <JamCanvasMap
      {...args}
      options={{
        ...options,
        defaultStyle,
      }}
    />
  );
}

export function HoverFeatureDrawer(
  args: Parameters<typeof JamCanvasFeature>["0"]
): ReturnType<typeof JamCanvasFeature> {
  const { atlasParams, options = {} } = args;
  const theme = useTheme();
  if (!atlasParams) return null;
  const { scale } = atlasParams;
  const strokeWidth = scale && Math.max(0.5, Math.min(scale / 5, 2));
  const strokeColor = lightenVariantTextColor({
    theme,
    variant: "tertiary",
    value: 0.3,
  });

  return (
    <JamCanvasFeature
      {...args}
      options={{
        ...options,
        defaultStyle: {
          fill: Const.BACKGROUND_COLOR,
          stroke: strokeColor,
          strokeWidth: strokeWidth * 2,
        },
      }}
    />
  );
}

export function SelectedFeatureDrawer(
  args: Parameters<typeof JamCanvasFeature>["0"]
): ReturnType<typeof JamCanvasFeature> {
  const { atlasParams, options = {} } = args;
  const theme = useTheme();
  if (!atlasParams) return null;
  const { scale } = atlasParams;
  const strokeWidth = scale && Math.max(0.5, Math.min(scale / 5, 2));
  const strokeColor = variantTextColor({
    theme,
    variant: "tertiary",
  });

  return (
    <JamCanvasFeature
      {...args}
      options={{
        ...options,
        defaultStyle: {
          fill: Const.BACKGROUND_COLOR,
          stroke: strokeColor,
          strokeWidth: strokeWidth * 3,
        },
      }}
    />
  );
}
