import styled from "styled-components";
import { sizeMixin } from "./main";
import { rgba } from "polished";
import { variantTextColor } from "../theme/variants";
import { ColorScale } from "../components/color-scale/main";
import { Search } from "../components/search/main";

export function shadowMixin(color: string) {
  return `box-shadow: 0px 0.25rem 2rem ${rgba(color, 0.24)};`;
}

export const StyledAppContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledBodyContainer = styled.div`
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  margin: 0.5rem;
  flex-direction: column;
  @media (min-width: ${({ theme }) => sizeMixin(theme.sizes.md)}) {
    flex-direction: row;
  }
`;

export const StyledHeaderContainer = styled.div`
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  & > * {
    margin-top: 0.5rem;
  }
  @media (min-width: ${({ theme }) => sizeMixin(theme.sizes.md)}) {
    flex-direction: row;
    & > section {
      flex: 1;
    }
    & > * {
      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }
`;

export const StyledMetricHeader = styled.section`
  color: ${({ theme }) => variantTextColor({ theme, variant: "tertiary" })};
  display: flex;
  flex-direction: column;
  align-items: baseline;
  h3 {
    margin: 0;
    flex: 1;
    font-size: 1.125rem;
    & + * {
      flex: none;
    }
  }
  // strong {
  //   text-align: right;
  // }
`;

export const StyledHeaderOptions = styled.div`
  // flex: 0.5;
  // display: flex;

  position: relative;
`;

export const StyledBox = styled.div`
  ${({ theme }) =>
    shadowMixin(variantTextColor({ theme, variant: "disabled" }))}
`;

export const StyledMapContainer = styled(StyledBox)`
  flex: 1;
  position: relative;
  overflow: hidden;
  max-height: 66vh;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => sizeMixin(theme.sizes.md)}) {
    max-width: calc(100vw - 1rem);
    min-height: 50vh;
    max-height: 50vh;
  }
`;

export const StyledFeatureTooltipContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  backdrop-filter: blur(5px);
  padding: 0.5rem;
  max-width: calc(100% - 8rem);
  box-sizing: border-box;
  border-radius: 0.25rem;
  border: 0.1rem solid
    ${({ theme }) => variantTextColor({ theme, variant: "tertiary" })};
  background-color: ${({ theme }) =>
    rgba(variantTextColor({ theme, variant: "off" }), 0.3)};
  strong {
    color: ${({ theme }) => variantTextColor({ theme, variant: "tertiary" })};
  }
  p {
    margin: 0;
  }
`;

export const StyledMapActionsContainer = styled.div`
  // position: absolute;
  // top: 0.5rem;
  // right: 0.5rem;
  // border: 0.1rem solid
  //   ${({ theme }) => variantTextColor({ theme, variant: "tertiary" })};
  display: flex;
  flex-direction: column;
  text-align: center;
  // backdrop-filter: blur(5px);
  // & > span {
  //   margin-top: 0.5rem;
  // }
  div {
    display: flex;
    align-items: center;
    & > button {
      flex: 1;
    }
  }
`;

export const StyledSearchContainer = styled(Search)`
  // position: absolute;
  // top: 0.5rem;
  // left: 0.5rem;
  margin-top: 0.5rem;
`;

export const StyledColorScaleContainer = styled(ColorScale)`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
`;

export const StyledSideContainer = styled(StyledBox).attrs({
  as: "aside",
})`
  max-height: 66vh;
  padding: 0.5rem;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 0 0;
  @media (min-width: ${({ theme }) => sizeMixin(theme.sizes.md)}) {
    flex: 0.75;
    margin: 0 0 0 0.5rem;
  }
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const StyledSideNote = styled.small`
  color: ${({ theme }) => variantTextColor({ theme, variant: "tertiary" })};
  margin-left: 0.5rem;
`;

export const StyledSideButtons = styled.section`
  display: flex;
  button {
    flex: 1;
  }
`;

export const StyledTableContainer = styled.div`
  // flex: 1;
  overflow-y: auto;
`;
