import { ReactElement } from "react";
import { VariantElementProps } from "../../types/components.types";
import { VariantProps } from "../../types/theme.types";

export interface MetaType<T = unknown> {
  thStyled?: JSX.Element;
  tdStyled?: JSX.Element;
  key?: string;
  label?: string;
  value?: (row: T) => ReactElement;
}

export interface DataType {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | undefined
    | [number, number];
}

export interface StyledTableProps extends VariantProps {
  outlined?: boolean;
}

export interface TableProps<T extends DataType>
  extends Omit<StyledTableProps, "children"> {
  meta: MetaType<T>[];
  data?: T[];
  selected?: T[];
  hideHeader?: boolean;
  useRowVariant?: boolean;
  hover?: T;
  onRowHover?: (values: T) => void;
  onRowClick?: (values: T) => void;
  children?: (args: TableRowProps<T>) => React.ReactElement;
}

export enum TableRowType {
  Head = "thead",
  Body = "tbody",
}

export interface TableRowProps<T extends DataType> extends VariantProps {
  rowType: TableRowType;
  values?: T;
  selected?: boolean;
  hover?: boolean;
  children?: TableProps<T>["children"] | React.ReactElement;
}

export interface RowProps extends VariantElementProps {
  onClick?: () => void;
  selected?: boolean;
  hover?: boolean;
}

export interface RowCellsProps<T extends DataType> {
  meta: MetaType<T>[];
  values?: T;
}

export interface PaginationRequest {
  currentPage: number;
  pageSize: number;
}

export interface PaginationProps extends Partial<PaginationRequest> {
  totalCount: number;
  showCount?: number;
  onChange?: (selectedPage: number) => void;
}

export interface TableCellProps
  extends React.ThHTMLAttributes<HTMLHtmlElement> {
  align?: "left" | "center" | "right";
  width?: string;
  padding?: string;
}
