import styled, { css } from "styled-components";
import { darken, lighten, rgba } from "polished";
import { variantTextColor, variantBgColor } from "./../../theme/variants";
import { StyledTableProps, RowProps, TableCellProps } from "./Table.types";
import { Button } from "../button/main";
import { hoverColor } from "../../styles/main";

export const StyledTableSection = styled.section.withConfig({
  shouldForwardProp: (prop) => !["centered"].includes(prop),
})<{ centered?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  ${({ centered }) => {
    return (
      centered &&
      css`
        align-items: center;
        justify-content: center;
      `
    );
  }}
`;

export const StyledTable = styled.table.withConfig({
  shouldForwardProp: (prop) => !["variant", "outlined"].includes(prop),
})<StyledTableProps>`
  ${(props) => {
    const { theme, outlined } = props;
    const thTextColor = variantTextColor(props);
    const thBgColor = variantBgColor(props);
    const tdBgColor = lighten(
      0.22,
      variantTextColor({ theme, variant: "disabled" })
    );
    return css`
      width: 100%;
      position: relative;
      border-spacing: 0;
      height: fit-content;
      & > thead {
        position: sticky;
        top: 0;
        line-height: 1.5;
        & > tr > th {
          text-align: left;
          padding: 0.5rem;
          background-color: ${darken(0.1, outlined ? thBgColor : thTextColor)};
          color: ${outlined ? thTextColor : thBgColor};
          border: 0.03rem solid ${outlined ? tdBgColor : thBgColor};
          &:first-child {
            border-top-left-radius: 0.25rem;
          }
          &:last-child {
            border-top-right-radius: 0.25rem;
          }
        }
      }
    `;
  }}
`;

export const StyledTableRow = styled.tr.withConfig({
  shouldForwardProp: (prop) =>
    !["clickable", "selected", "hover", "variant"].includes(prop),
})<RowProps>`
  ${(props) => {
    const { theme, onClick, selected, hover, variant = "off" } = props;
    const bgColorHover = hoverColor(props);
    const fontColorHover = variantBgColor(props);

    const bgColor = selected
      ? variantTextColor(props)
      : hover
      ? bgColorHover
      : rgba(variantBgColor({ theme, variant }), 0.25);
    const fontColor = selected
      ? variantBgColor(props)
      : hover
      ? fontColorHover
      : variantTextColor({ theme });

    const borderColor = variantTextColor({ theme, variant: "off" });

    return css`
      background-color: ${bgColor};
      color: ${fontColor};
      ${!selected &&
      `&:hover {
        background-color: ${bgColorHover};
        color: ${fontColorHover};
      }`}
      ${onClick &&
      css`
        cursor: pointer;
      `}

      
      & > td {
        border-bottom: 0.03rem solid ${borderColor};
        max-width: 33vh;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        vertical-align: top;
        & p {
          margin: 0;
        }

        & > td > p {
          margin: 0.25rem 0rem;
        }
      }
    `;
  }}
`;

export const StyledTableCell = styled.th.withConfig({
  shouldForwardProp: (prop) => !["align", "width", "padding"].includes(prop),
})<TableCellProps>`
  & {
    ${(props) => {
      const { align = "left", width, padding } = props;
      return css`
        text-align: ${align}!important;
        ${width &&
        `
          width:${width}!important;
          min-width:${width}!important;
          `}
        ${padding && `padding:${padding}!important;`}
      `;
    }}
  }
`;

export const StyledTableCellCenter = styled(StyledTableCell)`
  text-align: center !important;
`;

export const StyledTableCellRight = styled(StyledTableCell)`
  text-align: right !important;
`;

export const StyledPagination = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPaginationButton = styled(Button)`
  border-radius: 0.25rem;
`;

export const StyledNoData = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
