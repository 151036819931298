import { css, styled } from "styled-components";
import { Button } from "../button/main";
import { variantBgColor, variantTextColor } from "../../theme/variants";
import { StyledMetricHeader } from "../../styles/containers";

export const StyledCard = styled.ul`
  overflow-y: auto;
  margin: 0;
  padding: 0;
  li {
    margin: 0.5rem 0;
  }
  h4,
  p {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
`;

export const StyledCardHeader = styled(StyledMetricHeader)`
  flex-direction: column;
  small {
    position: relative;
    // padding-left: 0.75rem;
    cursor: pointer;
    i {
      position: absolute;
      right: -1rem;
    }
    & + h3 {
      margin-top: 0.5rem;
    }
  }
`;

export const StyledSideReportButton = styled(Button)`
  padding: 0.5rem;
  text-transform: uppercase;
`;

export const StyledCardTitle = styled.h4.withConfig({
  shouldForwardProp: (prop) => !["active"].includes(prop),
})<{ active?: boolean }>`
  ${({ active, theme }) => {
    return active
      ? css`
          background-color: ${variantTextColor({ theme, variant: "primary" })};
          color: ${variantBgColor({ theme, variant: "primary" })};
        `
      : css`
          color: ${variantTextColor({ theme, variant: "primary" })};
          background-color: ${variantBgColor({ theme, variant: "primary" })};
        `;
  }}
`;
