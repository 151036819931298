import React, { cloneElement } from "react";
import { DataType, RowCellsProps } from "./Table.types";

export function TableBodyCells<T extends DataType>({
  meta,
  values,
}: RowCellsProps<T>): React.ReactElement {
  return (
    <>
      {values &&
        meta.map(({ key, value, tdStyled: CellRenderer }, i) => {
          const children = value ? value(values) : values[key!];
          return CellRenderer ? (
            cloneElement(CellRenderer, { key: i, as: "td" }, <>{children}</>)
          ) : (
            <td key={i}>
              <>{children}</>
            </td>
          );
        })}
    </>
  );
}
