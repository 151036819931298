import styled, { css } from "styled-components";
import { lighten, darken } from "polished";
import { ButtonProps } from "./Button.types";
import { VariantProps } from "../../types/theme.types";
import { variantBgColor, variantTextColor } from "../../theme/variants";

export const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !["outlined", "variant", "noBorder", "off"].includes(prop),
})<ButtonProps & VariantProps>`
  font-family: "Gilroy";
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  line-height: 1;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.03em;

  & > label {
    display: inline-block;
    flex: 1;
  }

  & > i {
    font-size: 1.2rem;
    font-weight: 400;
    flex: none;
  }

  & > i + label {
    margin-left: 0.25rem;
  }

  ${({ outlined, disabled, noBorder, off, ...props }) => {
    const textColor = outlined
      ? variantTextColor(props)
      : variantBgColor(props);
    const offColor = off && variantTextColor({ ...props, variant: "off" });
    const backColor = outlined ? "transparent" : variantTextColor(props);
    const borderColor = variantTextColor(props);
    const bgColor = variantBgColor(props);
    return css`
      color: ${textColor};
      background-color: ${offColor || backColor};
      ${noBorder ? "border: none;" : `border: 0.03rem solid ${borderColor}`};
      &:not([disabled]) {
        cursor: pointer;
        & > label {
          cursor: pointer;
        }
        &:hover {
          color: ${lighten(0.1, offColor ? bgColor : textColor)};
          background-color: ${lighten(0.1, offColor ? borderColor : backColor)};
          ${!noBorder && `border-color: ${lighten(0.1, borderColor)};`}
        }
        &:active {
          color: ${darken(0.1, offColor ? bgColor : textColor)};
          background-color: ${darken(0.1, offColor ? borderColor : backColor)};
          ${!noBorder && `border-color: ${darken(0.1, borderColor)};`}
        }
      }
    `;
  }}
`;
