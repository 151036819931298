import React, {
  forwardRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { StyledPopup } from "./styled";
import { PopupProps } from "./Popup.types";
import { relativePosition } from "./relative-position";

export function isNumber(val: number | any): val is number {
  return !isNaN(parseInt(String(val)));
}

export const Popup = forwardRef<HTMLDivElement, PopupProps>(
  (
    {
      onClose,
      relatedRef,
      relatedParent,
      observe,
      children,
      dismissible = false,
      alignH = "center",
      alignV = "top",
      style = {},
      ...props
    },
    ref
  ) => {
    const popupRef = useRef(ref) as RefObject<HTMLDivElement>;
    const [related, setRelated] = useState<HTMLElement>();
    const [stylePosition, setStylePostion] = useState<{
      top?: string;
      left?: string;
      bottom?: string;
      right?: string;
      width?: string;
    }>();

    useEffect(() => {
      const escapeListener =
        onClose &&
        (function () {
          function listener(e: KeyboardEvent) {
            if (!onClose) return;
            if (e.key !== "Escape") return;
            e.stopImmediatePropagation();
            e.stopPropagation();
            onClose();
          }
          window.addEventListener("keydown", listener);
          // console.log("on");
          return function removeListener() {
            // console.log("off");
            window.removeEventListener("keydown", listener);
          };
        })();

      setRelated(relatedRef?.current || document.body);
      return () => {
        escapeListener && escapeListener();
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!related) return;
      const dom = related as HTMLElement;
      const resizeObserver = new ResizeObserver(() => {
        const { top, bottom, width, height, left, right } = relativePosition(
          dom,
          alignH,
          alignV,
          relatedParent
        );
        const style = {
          top: isNumber(top) ? `${top}px` : undefined,
          bottom: isNumber(bottom) ? `${bottom}px` : undefined,
          left: isNumber(left) ? `${left}px` : undefined,
          right: isNumber(right) ? `${right}px` : undefined,
        };
        observe === "width" && Object.assign(style, { width: `${width}px` });
        observe === "height" && Object.assign(style, { height: `${height}px` });
        setStylePostion(style);
      });
      resizeObserver.observe(dom);

      return () => {
        resizeObserver.unobserve(dom);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [related]);

    useEffect(() => {
      if (!popupRef.current) return;
      const { current: dom } = popupRef;

      const clickListener =
        onClose &&
        dismissible &&
        (function () {
          function listener(e: MouseEvent) {
            const target = e.target as HTMLElement;
            if (!related) return;
            if (!dom || !onClose) return;
            if (dom === target || !dom.contains(target)) onClose();
          }
          window.addEventListener("click", listener, true);
          return function removeListener() {
            window.removeEventListener("click", listener, true);
          };
        })();

      return () => {
        clickListener && clickListener();
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [related, popupRef]);

    if (!related) return null;

    return createPortal(
      <StyledPopup
        alignH={alignH}
        alignV={alignV}
        style={{ ...stylePosition, ...style }}
        ref={popupRef}
        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        {...props}
      >
        {stylePosition && children}
      </StyledPopup>,
      related
    );
  }
);
