import React, { cloneElement } from "react";
import { DataType, RowCellsProps } from "./Table.types";

export function TableHeadCells<T extends DataType>({
  meta,
}: RowCellsProps<T>): React.ReactElement {
  return (
    <>
      {meta.map((item, i) => {
        const { key, label, thStyled: Renderer } = item;
        const children = label || key;
        return Renderer ? (
          cloneElement(Renderer, { key: i, as: "th" }, children)
        ) : (
          <th key={i}>{children}</th>
        );
      })}
    </>
  );
}
