import { LevelResults, LocationProps, MetricSchema } from "abpi-common";
import { StyledCard, StyledCardTitle } from "./styled";

export function Quartile(
  index: number,
  value: number | string,
  current: number
) {
  let text = (
    <>
      Q{index}: {value}
    </>
  );
  if (current === index) text = <b>{text}</b>;
  return text;
}

export function CardMetrics(args: {
  levelType?: LocationProps;
  metrics?: LevelResults[];
  currentMetric?: MetricSchema;
}) {
  const { metrics, levelType, currentMetric } = args;
  if (!metrics?.length) return null;
  return (
    <StyledCard>
      {metrics?.map((item, i) => {
        const {
          formatter,
          label,
          valueType,
          populationField,
          totalField,
          groupType,
        } = item.metric;
        if (item.value === undefined) return <></>;
        const useTotal = !populationField && !totalField;
        const value = formatter ? formatter.format(item.value) : item.value;
        const total = formatter ? formatter.format(item.total) : item.total;
        const avg = formatter ? formatter.format(item.avg) : item.avg;
        const active = currentMetric?.groupType === groupType;
        // const q1 = formatter ? formatter.format(item.q1) : item.q1;
        // const q2 = formatter ? formatter.format(item.q2) : item.q2;
        // const q3 = formatter ? formatter.format(item.q3) : item.q3;
        // const q4 = formatter ? formatter.format(item.q4) : item.q4;
        return (
          <li key={i}>
            <StyledCardTitle active={active}>
              {label}: {value} (Ranked: {item.ranking} of {item.count})
            </StyledCardTitle>
            {useTotal && (
              <p>
                UK Total {valueType}: {total}
              </p>
            )}
            <p>
              Average for {levelType?.label}: {avg}{" "}
              {/*`(Q1: {q1} / Q3: {q3})`*/}
            </p>
            {/* <p>Average Distribution:</p>
        <ul>
          <li>{Quartile(1, q1, item.quartile)}</li>
          <li>{Quartile(2, q2, item.quartile)}</li>
          <li>{Quartile(3, q3, item.quartile)}</li>
          <li>{Quartile(4, q4, item.quartile)}</li>
        </ul> */}
          </li>
        );
      })}
    </StyledCard>
  );
}
