import {
  ErrorLoading,
  JamCanvas,
  ResizableContainer,
  SpinnerLoading,
  useMapDrawer,
} from "@hokuto/jam-ui";
import { MapComponentProps } from "./Map.types";
import { useTheme } from "styled-components";
import { variantBgColor, variantTextColor } from "../../theme/variants";
import {
  HoverFeatureDrawer,
  MapDrawer,
  SelectedFeatureDrawer,
} from "./drawers";
import { LightTheme } from "../../theme/light";
import { cloneElement } from "react";

export function MapComponent(props: MapComponentProps) {
  const {
    paramsError,
    paramsObserver,
    currentMetric,
    mapFeature,
    selectedData,
    atlasProps,
  } = props;

  const theme = useTheme() || LightTheme;
  const mapDrawer = useMapDrawer();
  const bgColor = theme && variantBgColor({ theme });
  const { styleId } = currentMetric;

  return (
    <ResizableContainer style={{ position: "relative" }}>
      {(size) => {
        const loadingSize = Math.min(
          size.width / 2,
          size.height / 2,
          theme.fontSize * 10
        );

        let spinner = paramsError ? (
          <ErrorLoading />
        ) : !mapDrawer.isReady ? (
          <SpinnerLoading />
        ) : undefined;

        spinner =
          spinner &&
          cloneElement(spinner, {
            width: loadingSize,
            height: loadingSize,
            color: variantTextColor({ theme, variant: "primary" }),
            style: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            },
          });

        return (
          <>
            <JamCanvas
              {...atlasProps}
              {...size}
              paramsObserver={paramsObserver}
              bgColor={bgColor}
              maxZoom={300}
            >
              <MapDrawer
                options={{ styleId }}
                canvasDrawer={mapDrawer.canvasDrawer}
              />
              <HoverFeatureDrawer featureId={mapFeature?.featureId} />
              <SelectedFeatureDrawer featureId={selectedData?.featureId} />
            </JamCanvas>
            {spinner}
          </>
        );
      }}
    </ResizableContainer>
  );
}
