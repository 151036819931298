import { Fragment } from "react";
import { SiteResults } from "abpi-common";
import { StyledCard } from "./styled";
import { SitesLocationSchema } from "../../hooks/sites";
import { MetaType } from "../table/Table.types";
import { Table } from "../table/main";

export function CardSites(args: { sites?: SitesLocationSchema[] }) {
  const { sites } = args;
  if (!sites) return null;
  return (
    <StyledCard>
      {sites.map(({ type, values }, i) => {
        const meta: MetaType<{ label: string }>[] = [
          {
            key: "label",
            label: type.label,
          },
        ];
        const data = values.reduce(
          (labels: { label: string }[], site: SiteResults) => {
            const { label } = site;
            !labels.find((item) => item.label === label) &&
              labels.push({ label });
            return labels;
          },
          []
        );
        if (!data.length) return null;
        return (
          <Fragment key={i}>
            <Table meta={meta} data={data} variant="tertiary" useRowVariant />
          </Fragment>
        );
      })}
    </StyledCard>
  );
}
