import { useTheme } from "styled-components";
import { SelectedForeColor } from "abpi-common";
import { variantBgColor, variantTextColor } from "../theme/variants";
import { LogoSchema } from "../components/metrics/Metrics.types";

export function useLogo(args: LogoSchema) {
  const { selected, ...props } = args;
  const theme = useTheme();
  const primaryColor = theme ? variantTextColor({ theme }) : "black";
  const secondaryColor = theme ? variantBgColor({ theme }) : "white";

  const foreColor = selected ? secondaryColor : primaryColor;
  const mainColor = selected ? primaryColor : SelectedForeColor;

  return {
    mainColor,
    foreColor,
    primaryColor,
    secondaryColor,
    selected,
    props,
  };
}
