import { createGlobalStyle, DefaultTheme } from "styled-components";
import {
  darkenVariantTextColor,
  lightenVariantTextColor,
  variantBgColor,
  variantTextColor,
} from "../theme/variants";
import { lighten } from "polished";
import { ThemeProps } from "../types/styled.types";

export const DURATION = "0.2s";

export function hoverColor({ theme, variant = "primary" }: ThemeProps) {
  return lighten(0.3, variantTextColor({ theme, variant }));
}

export function darkenDisabledColor(theme: DefaultTheme, value: number = 0.1) {
  return darkenVariantTextColor({
    theme,
    variant: "disabled",
    value,
  });
}

export function lightenDisabledColor(theme: DefaultTheme, value: number = 0.1) {
  return lightenVariantTextColor({
    theme,
    variant: "disabled",
    value,
  });
}

export function sizeMixin(px: number) {
  return `${px}px`;
}

export const MainStyles = createGlobalStyle`
  html{
    font-size: ${({ theme }) => sizeMixin(theme.fontSize)};
    color: ${({ theme }) => variantTextColor({ theme })};
    background-color: ${({ theme }) => variantBgColor({ theme })};
    font-family: Gilroy, Verdana, Arial;
    height: 100%;
  }
  body,
  div#root {
    width: 100%;
    min-height: 100%;
    margin: 0;
    display:flex;
    overflow-x: hidden;
    min-width: ${({ theme }) => sizeMixin(theme.sizes.xs)};
  }
  a{
    &,
    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => variantTextColor({ theme, variant: "primary" })};
      text-decoration: underline;
    }
    &:hover{
      color: ${({ theme }) =>
        lightenVariantTextColor({ theme, variant: "primary", value: 0.1 })};
    }
  }
`;
