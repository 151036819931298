import { cloneElement, createRef, useEffect, useState } from "react";
import { MetricsDropdownProps } from "./Metrics.types";
import {
  StyledMetricsDropdown,
  StyledMetricsDropdownInput,
  IconRight,
  StyledMetricItems,
  StyledMetricContainer,
  StyledMetricDetails,
} from "./styled";
import { Popup } from "../popup/main";
import { MetricSchema } from "abpi-common";

export function MetricsDropdown(args: MetricsDropdownProps) {
  const dropdownRef = createRef<HTMLDivElement>();
  const [open, setOpen] = useState<boolean>();
  const { selected, options, onChange, label, children } = args;

  const isSelected = selected && options?.includes(selected);

  function onSelection(metric: MetricSchema) {
    setOpen(false);
    onChange && onChange(metric);
  }

  useEffect(() => {
    const clickListener = (function () {
      function listener(e: MouseEvent) {
        const target = e.target as HTMLElement;
        const { current } = dropdownRef;
        if (!current) return;
        if (open && current.contains(target)) return;
        setOpen(false);
      }
      window.addEventListener("click", listener, true);
      return function removeListener() {
        window.removeEventListener("click", listener, true);
      };
    })();

    return () => {
      clickListener && clickListener();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  return (
    <StyledMetricContainer
      selected={isSelected}
      open={open}
      ref={dropdownRef}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <StyledMetricDetails>
        <h3>{label!}</h3>
        <StyledMetricsDropdown
          data-testid="input-container"
          selected={isSelected}
        >
          <IconRight
            name={"chevron"}
            rotate={open ? "top" : "bottom"}
            data-testid="input-icon-right"
          />
          <StyledMetricsDropdownInput
            id={"textfield"}
            data-testid="input"
            placeholder={isSelected ? selected.label : "Select metric"}
            readOnly
          />
        </StyledMetricsDropdown>
      </StyledMetricDetails>
      {children &&
        cloneElement(children, {
          selected: isSelected,
        })}
      {open && (
        <Popup
          relatedRef={dropdownRef}
          relatedParent
          alignH="center"
          alignV="bottom"
          observe="width"
          style={{ zIndex: 1 }}
          onClose={() => {
            setOpen(false);
          }}
        >
          <MetricsDropdownItems
            selected={selected}
            options={options}
            onChange={onSelection}
          />
        </Popup>
      )}
    </StyledMetricContainer>
  );
}

export function MetricsDropdownItems(args: MetricsDropdownProps) {
  const { options, selected, onChange } = args;
  return (
    <StyledMetricItems>
      <ul>
        {options?.map((item, i) => {
          return (
            <li
              key={i}
              data-selected={item === selected}
              onClick={() => {
                onChange && onChange(item);
              }}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </StyledMetricItems>
  );
}
