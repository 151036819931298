import { LocationProps } from "abpi-common";
import { StyledMapActionsContainer } from "../../styles/containers";
import { Button } from "../button/main";
import { Icon } from "../icon/main";

export function MapActions(args: {
  locations: LocationProps[];
  selected?: LocationProps;
  onReset: () => void;
  onSelection: (selected?: LocationProps) => void;
}) {
  const { locations, selected, onSelection, onReset } = args;

  return (
    <StyledMapActionsContainer>
      <span>Focus:</span>
      <div>
        <Button
          variant="tertiary"
          disabled={!selected}
          onClick={() => onSelection && onSelection(undefined)}
        >
          Auto
        </Button>
        {locations.map((item, i) => {
          return (
            <Button
              key={i}
              variant="tertiary"
              disabled={selected === item}
              onClick={() => onSelection && onSelection(item)}
            >
              {item.label}
            </Button>
          );
        })}
      </div>
      <Button outlined onClick={onReset} variant="tertiary">
        <label>Reset Map</label>
        <Icon name="close-surrounding" title="Reset map" />
      </Button>
    </StyledMapActionsContainer>
  );
}
