import { useEffect, useState } from "react";
import { useApi, jsonFetch } from "@hokuto/jam-ui";
import { AddressLookupResponse } from "../components/search/Search.types";

export function useSearch() {
  const [searchResults, setSearchResults] = useState<AddressLookupResponse[]>();
  const searchApi = useApi<typeof searchResults>();
  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    if (textSearch.length < 3) return;
    const action = setTimeout(() => {
      const params = new URLSearchParams({
        q: textSearch,
      }).toString();

      searchApi.setDataRequest(
        jsonFetch<AddressLookupResponse[]>(
          `${process.env.REACT_APP_ABPI_SERVER}/lookup/address?${params}`
        )
      );
    }, 300);
    return () => {
      action && clearTimeout(action);
      setSearchResults(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSearch]);

  useEffect(() => {
    searchApi.dataRequest
      ?.then((data) => {
        setSearchResults(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [searchApi.dataRequest]);

  return {
    textSearch,
    setTextSearch,
    searchResults,
    setSearchResults,
    searchApi,
  };
}
