import styled, { css } from "styled-components";
import { HorizontalAlignment, VerticalAlignment } from "./Popup.types";
export const StyledPopup = styled.div.withConfig({
  shouldForwardProp: (prop) => !["alignH", "alignV"].includes(prop),
})<{
  alignH: HorizontalAlignment;
  alignV: VerticalAlignment;
}>`
  // max-width: 30vw;
  // max-height: 50vh;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;

  ${({ alignH, alignV }) => {
    let transform;
    switch (alignH) {
      case "left":
        transform = `translateX(-100%)`;
        break;
      case "center":
        transform = `translateX(-50%)`;
        break;
    }
    switch (alignV) {
      case "top":
        transform = `${transform || ""} translateY(-100%)`;
        break;
      case "middle":
        transform = `${transform || ""} translateY(-50%)`;
        break;
    }
    return css`
      ${transform && `transform: ${transform};`}
    `;
  }}
`;
