import { MetricSchema, LocationProps } from "abpi-common";
import { MetaType } from "../table/Table.types";
import { Table } from "../table/main";
import { StyledTableCellCenter, StyledTableCellRight } from "../table/styled";
import { MapTablePops, MetricValue } from "./Map.types";
import { StyledMessage } from "./styled";

export function getMeta(
  levelType: LocationProps,
  metric: MetricSchema
): MetaType<MetricValue>[] {
  const { label } = levelType;
  return [
    {
      key: "ranking",
      label: "#",
      value: function (row) {
        return <>{row.ranking ? `#${row.ranking}` : "N/A"}</>;
      },
      tdStyled: <StyledTableCellCenter width="2rem" align="center" />,
      thStyled: <StyledTableCellCenter width="2rem" align="center" />,
    },
    { key: "name", label },
    {
      key: "value",
      label: metric.valueType,
      tdStyled: <StyledTableCellRight align="right" />,
      thStyled: <StyledTableCellRight align="right" />,
      value: function (row) {
        return (
          <b>
            {metric.formatter ? metric.formatter.format(row.value) : row.value}
          </b>
        );
      },
    },
  ];
}

export function MapTable(args: MapTablePops) {
  const {
    mapFeature,
    selectMapFeature,
    levelType,
    metric,
    selected,
    ...props
  } = args;
  const { data } = props;
  const hover =
    mapFeature &&
    data?.find(
      (item) =>
        item.featureId === mapFeature?.featureId &&
        item.mapId === mapFeature?.mapId
    );
  if (!levelType) return <></>;
  const features = selected?.map(({ featureId }) => featureId);
  const selectedData =
    features && data?.filter((item) => features.includes(item.featureId));
  if (!data) return null;
  if (!data.length)
    return (
      <StyledMessage>
        Adjust the position of the map to display results.
      </StyledMessage>
    );
  return (
    <Table
      variant="tertiary"
      meta={getMeta(levelType, metric)}
      hover={hover}
      useRowVariant
      selected={selectedData}
      onRowHover={({ mapId, featureId }) =>
        selectMapFeature({ mapId, featureId })
      }
      {...props}
    />
  );
}
