import { LogoSchema } from "./Metrics.types";
import { useLogo } from "../../hooks/logo";

export function EconomyLogo(args: LogoSchema) {
  const { props, mainColor, foreColor } = useLogo(args);
  return (
    <svg
      viewBox="0 0 162 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M118.578 111.26L146.938 120.573C144.236 125.962 140.327 130.642 135.297 134.512L118.578 111.26Z"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M111.554 101.059L111.562 51.5886C116.624 51.6742 120.969 52.4218 125.874 54.0337C138.513 58.1842 148.792 67.0535 154.826 79.0221C160.698 90.6715 161.858 103.886 158.128 116.361L111.554 101.059Z"
        stroke={mainColor}
        strokeWidth="1.5"
      />
      <path
        d="M110.487 143C99.6088 143 89.3845 138.725 81.6987 130.954C65.8133 114.897 65.8133 88.7793 81.6987 72.7225C88.1152 66.2438 95.6531 62.3269 104.133 61.0732V103.979L106.43 107.118L128.997 138.515C123.297 141.45 116.927 142.992 110.487 142.992V143Z"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M20.0063 82.3607H1V119.468H20.0063V82.3607Z"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M46.2534 67.8798H27.2471V119.468H46.2534V67.8798Z"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M73.4053 33.4873H54.3989V119.468H73.4053V33.4873Z"
        stroke={foreColor}
        strokeWidth="1.5"
      />
      <path
        d="M136.032 89.4891C136.032 90.8069 135.576 91.905 134.664 92.7836C133.768 93.6452 132.586 94.1436 131.116 94.2787V96.5595H129.925V94.3041C128.522 94.2365 127.323 93.8902 126.326 93.2651C125.346 92.64 124.662 91.7868 124.273 90.7055L125.439 90.0467C126.047 91.8882 127.543 92.8765 129.925 93.0116V85.4851C129.131 85.2148 128.497 84.9699 128.024 84.7502C127.568 84.5306 127.086 84.2349 126.58 83.8633C126.073 83.4916 125.701 83.0439 125.465 82.5201C125.228 81.9795 125.11 81.346 125.11 80.6195C125.11 79.3355 125.566 78.2712 126.478 77.4264C127.39 76.5817 128.539 76.1171 129.925 76.0327V73.7519H131.116V76.0327C132.146 76.1509 133.05 76.4804 133.827 77.021C134.621 77.5616 135.213 78.2965 135.601 79.2257L134.461 79.8593C133.87 78.3894 132.754 77.5532 131.116 77.3504V84.5475C131.724 84.7671 132.231 84.9614 132.636 85.1303C133.042 85.2993 133.481 85.5274 133.954 85.8146C134.427 86.1018 134.799 86.4059 135.069 86.7269C135.339 87.031 135.567 87.4196 135.753 87.8926C135.939 88.3656 136.032 88.8978 136.032 89.4891ZM126.453 80.6195C126.453 81.5318 126.74 82.2414 127.314 82.7482C127.889 83.2382 128.759 83.6943 129.925 84.1167V77.3251C128.894 77.4096 128.058 77.7559 127.416 78.3641C126.774 78.9723 126.453 79.7241 126.453 80.6195ZM131.116 92.961C132.231 92.8427 133.101 92.471 133.726 91.8459C134.351 91.2039 134.664 90.4183 134.664 89.4891C134.664 88.5599 134.368 87.8335 133.777 87.3097C133.185 86.786 132.298 86.313 131.116 85.8906V92.961Z"
        fill={mainColor}
      />
      <path
        d="M26.7942 23.0791L27.3777 22.6079L26.6328 21.6856L26.1185 22.7538L26.7942 23.0791ZM36.2974 34.8449L35.714 35.3162L36.207 35.9266L36.7945 35.4065L36.2974 34.8449ZM63.9018 10.4082L64.2312 9.73444L63.7804 9.51404L63.4047 9.84666L63.9018 10.4082ZM104.63 30.3196L104.3 30.9934L104.747 31.2118L105.122 30.8854L104.63 30.3196ZM139.475 0L130.974 1.65653L136.659 8.18978L139.475 0ZM9.82126 60.0595L27.47 23.4045L26.1185 22.7538L8.46976 59.4088L9.82126 60.0595ZM26.2108 23.5504L35.714 35.3162L36.8809 34.3737L27.3777 22.6079L26.2108 23.5504ZM36.7945 35.4065L64.399 10.9698L63.4047 9.84666L35.8003 34.2834L36.7945 35.4065ZM63.5724 11.082L104.3 30.9934L104.959 29.6458L64.2312 9.73444L63.5724 11.082ZM105.122 30.8854L134.875 4.99664L133.89 3.86505L104.137 29.7538L105.122 30.8854Z"
        fill={mainColor}
      />
      <circle cx="9.14564" cy="59.7342" r="3.62025" fill={mainColor} />
      <circle cx="27.2467" cy="22.6266" r="3.62025" fill={mainColor} />
      <circle cx="36.2975" cy="35.2975" r="3.62025" fill={mainColor} />
      <circle cx="64.3546" cy="9.9557" r="3.62025" fill={mainColor} />
      <circle cx="104.177" cy="29.8671" r="3.62025" fill={mainColor} />
    </svg>
  );
}
