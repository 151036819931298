import styled, { css } from "styled-components";
import { rgba } from "polished";
import { sizeMixin } from "./../../styles/main";
import { variantBgColor, variantTextColor } from "../../theme/variants";
import { Icon } from "../icon/main";
import { IconProps } from "../icon/Icon.types";
import { StyledBox } from "../../styles/containers";

export const StyledMetricsSelection = styled.div`
  flex: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0.5rem 0.5rem 0 0.5rem;
  @media (min-width: ${({ theme }) => sizeMixin(theme.sizes.md)}) {
    & > *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

export const StyledMetricContainer = styled(StyledBox).withConfig({
  shouldForwardProp: (prop) => {
    return !["selected", "open"].includes(prop);
  },
})<{ selected?: boolean; open?: boolean }>`
  flex: 1;
  padding: 0.5rem;
  display: flex;
  position: relative;
  cursor: pointer;
  color: ${({ selected, open, theme }) => {
    return selected || open
      ? variantBgColor({ theme, variant: "tertiary" })
      : variantTextColor({ theme, variant: "primary" });
  }};
  background-color: ${({ selected, open, theme }) => {
    return selected
      ? variantTextColor({ theme, variant: "tertiary" })
      : open
      ? rgba(variantTextColor({ theme, variant: "tertiary" }), 0.5)
      : variantBgColor({ theme, variant: "primary" });
  }};
  &:hover {
    ${({ selected, open, theme }) => {
      return (
        !selected &&
        !open &&
        `background-color: ${rgba(
          variantTextColor({ theme, variant: "tertiary" }),
          0.25
        )};`
      );
    }}
  }
  svg {
    flex: none;
    margin-left: 1rem;
    height: 4rem;
    @media (max-width: ${({ theme }) => sizeMixin(theme.sizes.md)}) {
      height: 2rem;
      margin: 0;
    }
  }
  @media (max-width: ${({ theme }) => sizeMixin(theme.sizes.md)}) {
    justify-content: center;
  }
`;

export const StyledMetricDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & > h3 {
    flex: 1;
    text-transform: uppercase;
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
  }
  @media (max-width: ${({ theme }) => sizeMixin(theme.sizes.md)}) {
    display: none;
  }
`;

export const StyledMetricsDropdown = styled.div.withConfig({
  shouldForwardProp: (prop) => !["selected"].includes(prop),
})<{ selected?: boolean }>`
  flex: none;
  position: relative;
  padding: 0.25rem 1rem 0.25rem 0rem;
  transition: color 0.3s;
  cursor: pointer;
  ${({ theme, selected }) => {
    const color = selected
      ? variantBgColor({ theme, variant: "primary" })
      : variantTextColor({ theme, variant: "primary" });
    return css`
      color: ${rgba(color, 0.75)};
      background-color: transparent;
      border-bottom: 0.03rem solid ${color};
      &:focus,
      &:focus-within {
        color: ${color};
      }
    `;
  }}
`;

export const StyledMetricsDropdownInput = styled.input`
  font-size: 1rem;
  line-height: 1.5;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  &,
  &::placeholder {
    color: inherit;
  }
`;

export const IconRight = styled(Icon)<IconProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.25rem;
  & ~ input[data-testid="input"] {
    padding-right: 0.5rem;
  }
`;

export const StyledMetricItems = styled.div`
  background-color: ${({ theme }) =>
    variantBgColor({ theme, variant: "tertiary" })};
  color: ${({ theme }) => variantTextColor({ theme, variant: "tertiary" })};
  border: 0.125rem solid
    ${({ theme }) => variantTextColor({ theme, variant: "tertiary" })};
  box-sizing: border-box;
  max-height: 25vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ul {
    max-height: 100%;
    overflow-y: auto;
    margin: 0;
    flex: 1;
    list-style: none;
    padding: 0;
    li {
      cursor: pointer;
      // line-height: 2rem;
      padding: 0.25rem;
      &[data-selected="true"] {
        background-color: ${({ theme }) =>
          variantTextColor({ theme, variant: "tertiary" })};
        color: ${({ theme }) => variantBgColor({ theme, variant: "tertiary" })};
      }
      &:hover:not([data-selected="true"]) {
        background-color: ${({ theme }) =>
          rgba(variantTextColor({ theme, variant: "tertiary" }), 0.5)};
        color: ${({ theme }) => variantBgColor({ theme, variant: "tertiary" })};
      }
      &:not(:last-child) {
        border-bottom: 0.03rem solid
          ${({ theme }) => variantTextColor({ theme, variant: "off" })};
      }
    }
  }
`;
