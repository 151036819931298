import { styled } from "styled-components";
import { lighten } from "polished";
import { SelectedForeColor } from "abpi-common";
import { variantTextColor } from "../../theme/variants";

export const StyledPath = styled.path.withConfig({
  shouldForwardProp: (prop) => !["selected"].includes(prop),
})<{ selected?: boolean }>`
  fill: transparent;
  stroke: transparent;
  stroke-width: 0;
  transition: all 1s ease-in-out;
  &[data-hover="true"] {
    stroke-width: 2;
    stroke: ${({ selected }) => {
      const color = SelectedForeColor;
      if (selected) return color;
      return lighten(0.3, color);
    }};
  }
  &:not([data-off="true"]) {
    cursor: pointer;
  }
`;

export const StyledMessage = styled.p`
  color: ${({ theme }) => variantTextColor({ theme, variant: "primary" })};
  text-align: center;
`;
