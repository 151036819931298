export var ValueType;
(function (ValueType) {
    ValueType["Count"] = "Count";
    ValueType["GBP"] = "GBP";
    ValueType["Percent"] = "Percentage";
    ValueType["PerCapita"] = "p/100k";
})(ValueType || (ValueType = {}));
export var WpcGroup;
(function (WpcGroup) {
    WpcGroup["Country"] = "country_code";
    WpcGroup["Region"] = "region_code";
    WpcGroup["Wpc"] = "parliamentary_constituency_code";
})(WpcGroup || (WpcGroup = {}));
export var RegionGroup;
(function (RegionGroup) {
    RegionGroup["Country"] = "country_code";
    RegionGroup["Region"] = "region_code";
})(RegionGroup || (RegionGroup = {}));
export var WpcAggr;
(function (WpcAggr) {
    WpcAggr["Manufacturing"] = "manufacturing_site_count";
    WpcAggr["RD"] = "rd_site_count";
    WpcAggr["PartnershipsNhs"] = "nhs_partnership_count";
    WpcAggr["CaseStudy"] = "case_study_count";
    WpcAggr["Jobs"] = "industry_job_observations";
    WpcAggr["Contribution"] = "industry_contributions_to_HCO_gbp";
    WpcAggr["Price"] = "wpc_gva_gbp";
})(WpcAggr || (WpcAggr = {}));
export var RegionAggr;
(function (RegionAggr) {
    RegionAggr["Trials"] = "clinical_trial_recruitment_count";
})(RegionAggr || (RegionAggr = {}));
export var GroupType;
(function (GroupType) {
    GroupType["Manufacturing"] = "manufacturingGroup";
    GroupType["ManufacturingPerCapita"] = "manufaturingPerCapita";
    GroupType["RD"] = "rdGroup";
    GroupType["RDPerCapita"] = "rdPerCapita";
    GroupType["PartnershipsNhs"] = "partnershipsNhsGroup";
    GroupType["PartnershipsNhsPerCapita"] = "partnershipsNhsPerCapitaGroup";
    GroupType["Jobs"] = "jobsGroup";
    GroupType["JobsPercent"] = "jobsPercentGroup";
    GroupType["JobsPerCapita"] = "jobsPerCapitaGroup";
    GroupType["Contribution"] = "contributionGroup";
    GroupType["ContributionPerCapita"] = "contributionPerCapitaGroup";
    GroupType["Price"] = "priceGroup";
    GroupType["PricePerCapita"] = "pricePerCapitaGroup";
    GroupType["Trials"] = "trialsGroup";
    GroupType["TrialsPerCapita"] = "trialsPerCapitaGroup";
})(GroupType || (GroupType = {}));
export var SiteTypes;
(function (SiteTypes) {
    SiteTypes["Manufacturing"] = "sites/manufacturing";
    SiteTypes["PartnershipsNhs"] = "sites/partnerships";
    SiteTypes["RD"] = "sites/rd";
})(SiteTypes || (SiteTypes = {}));
export var SiteLabel;
(function (SiteLabel) {
    SiteLabel["PartnershipsNhs"] = "NHS/Industry collaborations";
    SiteLabel["Manufacturing"] = "Manufacturing sites";
    SiteLabel["RD"] = "Research sites";
})(SiteLabel || (SiteLabel = {}));
export var SiteLabelProp;
(function (SiteLabelProp) {
    SiteLabelProp["Manufacturing"] = "business_name";
    SiteLabelProp["RD"] = "business_name";
    SiteLabelProp["PartnershipsNhs"] = "institution_name";
})(SiteLabelProp || (SiteLabelProp = {}));
export var LocationCodeProp;
(function (LocationCodeProp) {
    LocationCodeProp["Country"] = "country_code";
    LocationCodeProp["Region"] = "RGN24CD";
    LocationCodeProp["Wpc"] = "PCON24CD";
})(LocationCodeProp || (LocationCodeProp = {}));
export var LocationLabelProp;
(function (LocationLabelProp) {
    LocationLabelProp["Country"] = "country_name";
    LocationLabelProp["Region"] = "RGN24NM";
    LocationLabelProp["Wpc"] = "PCON24NM";
})(LocationLabelProp || (LocationLabelProp = {}));
export var MetricTypes;
(function (MetricTypes) {
    MetricTypes["Manufacturing"] = "manufacturing/count";
    MetricTypes["ManufacturingPerCapita"] = "manufaturing/percapita";
    MetricTypes["RD"] = "rd/count";
    MetricTypes["RDPerCapita"] = "rd/percapita";
    MetricTypes["PartnershipsNhs"] = "partnerships/nhs/count";
    MetricTypes["PartnershipsNhsPerCapita"] = "partnerships/nhs/percapita";
    MetricTypes["Jobs"] = "jobs/count";
    MetricTypes["JobsPerCapita"] = "jobs/percapita";
    MetricTypes["JobsPercent"] = "jobs/percent";
    MetricTypes["Price"] = "price/count";
    MetricTypes["PricePerCapita"] = "price/percapita";
    MetricTypes["Contribution"] = "contribution/count";
    MetricTypes["ContributionPerCapita"] = "contribution/percapita";
    MetricTypes["Trials"] = "trials/count";
    MetricTypes["TrialsPerCapita"] = "trials/percapita";
})(MetricTypes || (MetricTypes = {}));
export var MetricDescription;
(function (MetricDescription) {
    MetricDescription["Trials"] = "The data below shows the number of individuals who have been enrolled in industry clinical trials based on the location of the site that recruits them. Where a participant lives is not always the same area that their trial site is based, and participants will often travel to access specialist treatment and diagnostics.";
    MetricDescription["Partnerships"] = "Data captured below includes collaborations which involve donations, grants, or funding for collaborative working which are reported via Disclosure UK. The data does not include value generated for NHS organisations through research and development such as clinical trials.";
    MetricDescription["Manufacturing"] = "Manufacturing sites can vary from facilities which employ thousands of people and produce millions of packs of medicines annually, to small manufacturing units attached to hospitals which produce specialised medicines in small volumes.";
    MetricDescription["Jobs"] = "Constituency data is unavailable in Scotland and Northern Ireland. An even distribution of jobs across the population has been applied for the purposes of this tool, but does not accurately reflect the location of employees below country level in Scotland and Northern Ireland.";
    MetricDescription["JobsPercent"] = "This data shows the number of people employed in pharmaceutical industry jobs as a percentage of those in full or part-time employment across the economy. Constituency data is unavailable in Scotland and Northern Ireland. An even distribution of jobs across the population has been applied for the purposes of this tool, but does not accurately reflect the location of employees below country level in Scotland and Northern Ireland.";
    MetricDescription["Research"] = "Research sites vary significantly in size and scale, ranging from major research and & development (R&D) facilities which employ thousands of individuals, to facilities occupied by smaller spin-out companies. They also include specialist contract research organisations which undertake or host R&D activity on behalf of pharmaceutical companies.";
})(MetricDescription || (MetricDescription = {}));
