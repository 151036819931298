import { PinRequest } from "@hokuto/jam-core";
import { LocationProps, MetricSchema } from "abpi-common";
import { MetricValue } from "../map/Map.types";
import { SitesLocationSchema } from "../../hooks/sites";

export interface CardProps {
  selectedData: MetricValue;
  currentSites?: SitesLocationSchema[];
  currentMetric?: MetricSchema;
  levelType?: LocationProps;
  setPin?: (pin?: PinRequest) => void;
  onClose?: () => void;
}

export enum CardTabs {
  Metrics = "Metrics",
  Sites = "Sites",
}
