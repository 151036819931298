import styled from "styled-components";
import { variantTextColor } from "../../theme/variants";

export const StyledColorScale = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1.25rem;
  &:before,
  &:after {
    position: absolute;
    left: -0.5rem;
    transform: translateX(-100%);
    font-size: 0.75rem;
  }
  &:before {
    bottom: 0;
    content: "Low";
  }
  &:after {
    content: "High";
    top: 0;
  }
`;

export const StyledColorScaleStep = styled.span.withConfig({
  shouldForwardProp: (prop) => !["color"].includes(prop),
})<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 1rem;
  height: 0.25rem;
  margin: 0.061rem;
  border-radius: 0.125rem;
`;

export const StyledColorScaleLabel = styled.span.withConfig({
  shouldForwardProp: (prop) => !["alignV"].includes(prop),
})<{ alignV: "top" | "bottom" }>`
  position: absolute;
  left: -0.5rem;
  transform: translateX(-100%);
  ${({ alignV }) => `${alignV}: 0;`}
`;

export const StyledColorScaleLabelSelected = styled.span`
  position: absolute;
  left: 0.05rem;
  bottom: -1.25rem;
  width: 1rem;
  height: 1rem;
  background-color: ${({ theme }) =>
    variantTextColor({ theme, variant: "tertiary" })};
  &:after {
    position: absolute;
    left: -0.5rem;
    transform: translateX(-100%);
    content: "Selected";
    font-size: 0.75rem;
  }
`;
